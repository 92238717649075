import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import ReactPlayer from "react-player";
import EditForm from "./components/EditForm";
import DeleteForm from "./components/DeleteForm";

import Moment from "react-moment";
import "moment-timezone";

import { connect } from "react-redux";

// Redux Actions
import { getVideo } from "../../actions/video";

function Video(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);

  const { videoId } = useParams();

  useEffect(() => {
    dispatch(getVideo(videoId));
  }, [videoId, dispatch]);

  const redirect = () => {
    if (props.auth.user.admin) {
      history.push("/admin/videos");
    } else {
      history.push("/library");
    }
  };

  const video = props.video.video;

  if (video) {
    return (
      <div id="video" className="page">
        <h3>{video.title}</h3>

        <div className="action-button-container">
          {!showEditForm && !showDeleteForm && (
            <button className="edit" onClick={() => setShowEditForm(true)}>
              Edit Details
            </button>
          )}

          {!showEditForm && !showDeleteForm && (
            <button className="save" onClick={() => history.goBack()}>
              Save & Return
            </button>
          )}

          {!showDeleteForm && !showEditForm && (
            <button className="delete" onClick={() => setShowDeleteForm(true)}>
              Delete Video
            </button>
          )}
        </div>

        {showEditForm && !showDeleteForm && (
          <EditForm video={video} hideForm={() => setShowEditForm(false)} />
        )}

        {showDeleteForm && !showEditForm && (
          <DeleteForm
            video={video}
            videoId={video._id}
            hideForm={() => setShowDeleteForm(false)}
            redirect={redirect}
          />
        )}

        {!showEditForm && (
          <div className="video-details">
            {props.auth.user.admin && (
              <div className="video-details-item">
                <div className="name">User</div>
                <div className="details">{video.username}</div>
              </div>
            )}

            <div className="video-details-item">
              <div className="name">Uploaded</div>
              <div className="details">
                <Moment format="MM-DD-YY">{video.createdAt}</Moment>
              </div>
            </div>

            <div className="video-details-item">
              <div className="name">Description</div>
              <div className="details">{video.description}</div>
            </div>

            <div className="video-details-item">
              <div className="name">Tags</div>
              {video.tags && (
                <div className="details">
                  {video.tags.map((tag) => {
                    return <p key={tag._id}>{tag.name}</p>;
                  })}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="video-preview">
          <ReactPlayer
            controls="true"
            width="100%"
            height="100%"
            url={video.url}
          />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    video: state.video,
  };
};

export default connect(mapStateToProps)(Video);
