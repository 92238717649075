import axios from "axios";
import config from "../config/keys";

const ROOT = config.rootUrl;

// Gets the user currently using the site
export function getAuthUser() {
  return function (dispatch) {
    axios
      .get(`${ROOT}/get_auth_user`, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(authError(response.data.error));
        } else {
          dispatch(clearAuthError());
          dispatch({
            type: "AUTHORIZE_USER",
            user: response.data.user,
            userId: response.data.user._id,
          });
        }
      })
      .catch((error) => {
        console.log("getAuthUser error", error);
        dispatch(authError("Sorry, there was an error fetching this user."));
      });
  };
}

export function login({ email, password, googleId, facebookId }, callback) {
  return function (dispatch) {
    // Submits email and password to the server
    axios
      .post(`${ROOT}/login`, {
        email,
        password,
        googleId,
        facebookId,
        userType: "Instructor",
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(authError(response.data.error));
        } else {
          dispatch(clearAuthError());
          localStorage.setItem("token", response.data.token);
          dispatch({ type: "AUTHORIZE_USER", user: response.data.user });
          callback();
        }
      })
      .catch((error) => {
        dispatch(authError("Incorrect email or password."));
      });
  };
}

export function updateUser(data, callback) {
  return function (dispatch) {
    axios
      .put(`${ROOT}/user/${data._id}`, data, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(authError(response.data.error));
        } else {
          dispatch(clearAuthError());
          dispatch({ type: "UPDATE_USER", user: response.data.user });
          callback();
        }
      })
      .catch((error) => {
        dispatch(authError("There was an error updating this user account."));
      });
  };
}

export function forgotPassword({ email }, callback) {
  return function (dispatch) {
    axios
      .post(`${ROOT}/forgot_password`, { email })
      .then((response) => {
        if (response.data.error) {
          dispatch(authError(response.data.error));
        } else {
          dispatch(clearAuthError());
          callback();
        }
      })
      .catch((error) => {
        dispatch(authError(error));
      });
  };
}

export function checkResetToken(token, callback) {
  return function (dispatch) {
    axios
      .post(`${ROOT}/check_reset_token`, { token })
      .then((response) => {
        if (response.data.error) {
          dispatch(authError(response.data.error));
        } else {
          dispatch(clearAuthError());
          callback();
        }
      })
      .catch((error) => {
        dispatch(authError(error));
      });
  };
}

export function resetPassword({ password, token }, callback) {
  return (dispatch) => {
    return axios
      .post(`${ROOT}/reset_password`, { password, token })
      .then((response) => {
        if (response.data.error) {
          dispatch(authError(response.data.error));
        } else {
          dispatch(clearAuthError());
          callback();
        }
      })
      .catch((error) => {
        dispatch(authError(error));
      });
  };
}

export function checkEmailToken(token, callback) {
  return function (dispatch) {
    axios
      .post(`${ROOT}/check_email_token`, { token })
      .then((response) => {
        if (response.data.error) {
          dispatch(authError(response.data.error));
        } else {
          if (callback) {
            dispatch(clearAuthError());
            callback();
          }
        }
      })
      .catch((error) => {
        dispatch(authError(error));
      });
  };
}

export function confirmEmail({ password, token }, callback) {
  return (dispatch) => {
    return axios
      .post(`${ROOT}/confirm_email`, { password, token })
      .then((response) => {
        if (response.data.error) {
          dispatch(authError(response.data.error));
        } else {
          clearAuthError();
          callback();
        }
      })
      .catch((error) => {
        dispatch(authError(error));
      });
  };
}

export function unauthorizeUser(callback) {
  localStorage.removeItem("token");
  callback();
  return { type: "UNAUTHORIZE_USER" };
}

export function authError(error) {
  return {
    type: "AUTH_ERROR",
    payload: error,
  };
}

export function clearAuthError() {
  return {
    type: "CLEAR_AUTH_ERROR",
  };
}
