import React, { useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from "react-router";

// Redux Actions 
import { checkEmailToken, confirmEmail } from '../../actions/auth';

function ConfirmEmail(props) {

    const dispatch = useDispatch();
    let { token } = useParams();

    const [showForm, setShowForm] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const { register, handleSubmit, errors } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        dispatch(checkEmailToken(token, () => {
            setShowForm(true);
        }));
    }, [token]);


    const onSubmit = (formData) => {

        dispatch(confirmEmail({ password: formData.password, token }, () => {
            setShowConfirmation(true);
            setShowForm(false);

        }));
    }


    return (
        <div id="page-confirm-email" className="auth">


            {showConfirmation && !props.auth.error &&
                <div className="auth-message">
                    <p>Your email address has been updated.</p>
                </div>
            }

            {
                props.auth.error &&
                <div className="auth-message">
                    <p>Sorry, this link has expired. Please update your password again from your account page.</p>
                </div>
            }

            {showForm && !props.auth.error && !showConfirmation &&
                <div className="auth-form-container">

                    <div className="auth-form">
                        <form id="login-form" onSubmit={handleSubmit(onSubmit)}>

                            <h3>Enter your password to confirm your new email address.</h3>

                            {props.auth && props.auth.error && !props.auth.error.message && (
                                <p className="form-error">{props.auth.error}</p>
                            )}

                            <div className="form-row">
                                <div className="form-input-container">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" name="password" {...register('password', { required: true })} />
                                    {errors && errors['password'] &&
                                        <div className="input-error">Password is required.</div>
                                    }
                                </div>

                            </div>



                            <div className="button-container">
                                <button type="submit">Submit</button>
                            </div>



                        </form>

                    </div>

                </div>

            }
        </div>
    );



}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(ConfirmEmail);

