import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
import { connect } from "react-redux";

import TimerForm from "./TimerForm";
import { MdRemoveCircle } from "react-icons/md";

function SeriesItem(props) {
  const video = props.video;
  if (video.video) {
    return (
      <div className="series-item">
        <div className="series-item-number">
          {props.key + 1 ? props.key + 1 : ""}
        </div>

        {!props.simple && video.video && (
          <ReactPlayer
            className="player"
            controls={true}
            height="100%"
            url={video.video.url}
          />
        )}

        <div className="series-item-info">
          <h4 className="title">
            {video.video.title}{" "}
            {!props.simple && (
              <NavLink to={`/video/${video.video._id}`}>More</NavLink>
            )}
          </h4>
          <TimerForm
            simple={props.simple}
            seriesId={props.seriesId}
            video={props.video}
          />

          <div className="desc">{video.video.description}</div>
          {!props.simple && (
            <div className="series-item-settings">
              <div className="series-item-action">
                <div
                  className="remove"
                  onClick={(e) => {
                    e.preventDefault();
                    props.removeFromSeries(video);
                  }}
                >
                  Remove
                  <MdRemoveCircle />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    series: state.series,
  };
};

export default connect(mapStateToProps)(SeriesItem);
