import axios from 'axios';
import config from '../config/keys';

const ROOT = config.rootUrl;

export function createVideo(data, callback) {
    return function (dispatch) {

        axios.post(`${ROOT}/video`, data,
            {
                headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
            })
            .then(response => {
                if (response.data.error) {
                    dispatch(videoError(response.data.error));
                } else {
                    dispatch(clearVideoError());
                    dispatch({ type: "CREATE_VIDEO", videos: response.data.videos });

                    if (callback) {
                        callback();
                    }
                }
            })
            .catch((error) => {
                dispatch(videoError("Sorry, there was an error adding this video."));
            });
    }
}

export function updateVideo(data, callback) {
    return function (dispatch) {

        axios.put(`${ROOT}/video`, data,
            {
                headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
            })
            .then(response => {
                if (response.data.error) {
                    dispatch(videoError(response.data.error));
                } else {
                    dispatch(clearVideoError());
                    dispatch({ type: "UPDATE_VIDEO", video: response.data.video });

                    if (callback) {
                        callback();
                    }
                }
            })
            .catch((error) => {
                dispatch(videoError("There was an error updating this video."));
            });
    }
}


export function deleteVideo(videoId, callback) {
    return function (dispatch) {


        axios.delete(`${ROOT}/video/${videoId}`,
            {
                headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
            })
            .then(response => {
                if (response.data.error) {
                    dispatch(videoError(response.data.error));
                } else {
                    dispatch(clearVideoError());
                    dispatch({ type: "DELETE_VIDEO", videoId: response.data.videoId });

                    if (callback) {
                        callback();
                    }

                }
            })
            .catch((error) => {
                dispatch(videoError("There was an error deleting this video."));
            });
    }
}

export function adminGetVideos(sortBy) {

    let direction = sortBy && sortBy.desc === true ? -1 : 1;
    let key = sortBy && sortBy.key ? sortBy.key : null;

    return function (dispatch) {
        axios.get(`${ROOT}/admin/videos?sortby=${key}&sortorder=${direction}`, {
            headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
        })
            .then(response => {
                if (response.data.error) {
                    dispatch(videoError(response.data.error));
                } else {
                    dispatch(clearVideoError());
                    dispatch({ type: "ADMIN_GET_VIDEOS", adminVideos: response.data.videos });
                }
            })
            .catch((error) => {
                dispatch(videoError("There was an error fetching videos."));
            });
    }
}


export function adminSearchVideos(sortBy, searchText) {
    let direction = sortBy && sortBy.desc === true ? -1 : 1;
    let key = sortBy && sortBy.key ? sortBy.key : null;

    if (searchText) {
        return function (dispatch) {
            axios.get(`${ROOT}/videos/search?sortby=${key}&sortorder=${direction}&searchtext=${searchText}`, {
                headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
            })
                .then(response => {
                    if (response.data.error) {
                        dispatch(videoError(response.data.error));
                    } else {
                        dispatch(clearVideoError());
                        dispatch({ type: "ADMIN_GET_VIDEOS", adminVideos: response.data.videos });
                    }
                })
                .catch((error) => {
                    dispatch(videoError("There was an error fetching videos."));
                });
        }
    }
}


export function userGetAllVideos(userId) {
    return function (dispatch) {
        axios.get(`${ROOT}/video/user/${userId}`, {
            headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
        })
            .then(response => {
                if (response.data.error) {
                    dispatch(videoError(response.data.error));
                } else {
                    dispatch(clearVideoError());
                    dispatch({ type: "USER_GET_ALL_VIDEOS", userVideos: response.data.videos });
                }
            })
            .catch((error) => {

                dispatch(videoError("There was an error fetching this user's videos."));
            });
    }
}



export function getVideo(videoId, callback) {
    return function (dispatch) {
        axios.get(`${ROOT}/video/${videoId}`, {
            headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
        })
            .then(response => {
                if (response.data.error) {
                    dispatch(videoError(response.data.error));
                } else {
                    dispatch({ type: "GET_VIDEO", video: response.data.video });
                    dispatch(clearVideoError());
                    if (callback) {
                        callback();
                    }

                }
            })
            .catch((error) => {
                dispatch(videoError("There was an error loading this video."));
            });
    }
}

export function videoError(error) {
    return {
        type: "VIDEO_ERROR",
        payload: error
    };
}

export function clearVideoError() {
    return {
        type: "CLEAR_VIDEO_ERROR"
    };
}
