import axios from "axios";
import config from "../config/keys";

const ROOT = config.rootUrl;

export function createSeries(data, history, callback) {
  return function (dispatch) {
    axios
      .post(`${ROOT}/series`, data, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(seriesError(response.data.error));
        } else {
          dispatch(clearSeriesError());
          dispatch({ type: "CREATE_SERIES", series: response.data.series });
          callback();
          history.push(`/series/${response.data.series._id}`);
        }
      })
      .catch((error) => {
        dispatch(
          seriesError("Sorry, there was an error creating this series.")
        );
        callback();
      });
  };
}

export function updateSeries(data, callback) {
  return function (dispatch) {
    axios
      .put(
        `${ROOT}/series`,
        data,

        {
          headers: { Authorization: "JWT " + localStorage.getItem("token") },
        }
      )
      .then((response) => {
        if (response.data.error) {
          dispatch(seriesError(response.data.error));
        } else {
          dispatch({ type: "UPDATE_SERIES", series: response.data.series });
          dispatch(clearSeriesError());
          if (callback) {
            callback();
          }
        }
      })
      .catch((error) => {
        dispatch(seriesError("There was an error updating this series."));
      });
  };
}

export function addVideoToSeries(data, callback) {
  return function (dispatch) {
    axios
      .post(
        `${ROOT}/series/video`,
        data,

        {
          headers: { Authorization: "JWT " + localStorage.getItem("token") },
        }
      )
      .then((response) => {
        if (response.data.error) {
          dispatch(seriesError(response.data.error));
        } else {
          dispatch({ type: "UPDATE_SERIES", series: response.data.series });
          dispatch(clearSeriesError());
          if (callback) {
            callback();
          }
        }
      })
      .catch((error) => {
        dispatch(seriesError("There was an error updating this series."));
      });
  };
}

export function deleteSeries(seriesId, callback) {
  return function (dispatch) {
    axios
      .delete(`${ROOT}/series/${seriesId}`, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(seriesError(response.data.error));
        } else {
          dispatch(clearSeriesError());
          dispatch({ type: "DELETE_SERIES", seriesId: response.data.seriesId });
          if (callback) {
            callback();
          }
        }
      })
      .catch((error) => {
        dispatch(seriesError("There was an error deleting this series."));
      });
  };
}

export function adminGetSeries(sortBy) {
  let direction = sortBy && sortBy.desc === true ? -1 : 1;
  let key = sortBy && sortBy.key ? sortBy.key : null;

  return function (dispatch) {
    axios
      .get(`${ROOT}/admin/series?sortby=${key}&sortorder=${direction}`, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(seriesError(response.data.error));
        } else {
          dispatch({
            type: "ADMIN_GET_SERIES",
            adminSeries: response.data.series,
          });
        }
      })
      .catch((error) => {
        dispatch(seriesError("There was an error fetching series."));
      });
  };
}

export function adminSearchSeries(sortBy, searchText) {
  let direction = sortBy && sortBy.desc === true ? -1 : 1;
  let key = sortBy && sortBy.key ? sortBy.key : null;

  if (searchText) {
    return function (dispatch) {
      axios
        .get(
          `${ROOT}/admin/series/search?sortby=${key}&sortorder=${direction}&searchtext=${searchText}`,
          {
            headers: { Authorization: "JWT " + localStorage.getItem("token") },
          }
        )
        .then((response) => {
          if (response.data.error) {
            dispatch(seriesError(response.data.error));
          } else {
            dispatch(clearSeriesError());
            dispatch({
              type: "ADMIN_GET_SERIES",
              adminSeries: response.data.series,
            });
          }
        })
        .catch((error) => {
          dispatch(seriesError("There was an error fetching series."));
        });
    };
  }
}

export function userGetAllSeries(userId) {
  return function (dispatch) {
    axios
      .get(`${ROOT}/series/user/${userId}`, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(seriesError(response.data.error));
        } else {
          dispatch(clearSeriesError());
          dispatch({
            type: "USER_GET_ALL_SERIES",
            userSeries: response.data.series,
          });
        }
      })
      .catch((error) => {
        dispatch(
          seriesError("There was an error fetching this user's series.")
        );
      });
  };
}

export function getSeries(seriesId, callback) {
  return function (dispatch) {
    axios
      .get(`${ROOT}/series/${seriesId}`, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(seriesError(response.data.error));
        } else {
          dispatch(clearSeriesError());
          dispatch({
            type: "GET_SERIES",
            series: response.data.series,
            workoutVideos: response.data.workoutVideos,
          });
          if (callback) {
            callback();
          }
        }
      })
      .catch((error) => {
        dispatch(seriesError("There was an error loading this series."));
      });
  };
}

export function seriesError(error) {
  return {
    type: "SERIES_ERROR",
    payload: error,
  };
}

export function clearSeriesError() {
  return {
    type: "CLEAR_SERIES_ERROR",
  };
}
