export default function (
  state = { error: "", series: null, adminSeries: [], userSeries: [] },
  action
) {
  switch (action.type) {
    case "UNAUTHORIZE_USER":
      return {
        ...state,
        error: "",
        series: null,
        adminSeries: [],
        userSeries: [],
      };
    case "CREATE_SERIES":
      return {
        ...state,
        adminSeries: [action.series, ...state.adminSeries],
        userSeries: [action.series, ...state.userSeries],
        series: action.series,
        error: "",
      };

    case "UPDATE_SERIES":
      let adminSeries = state.adminSeries;

      let updatedSeriesAdmin = adminSeries.map((s) => {
        if (s._id === action.series._id) {
          return action.series;
        } else {
          return s;
        }
      });
      let userSeries = state.userSeries;
      let updatedSeriesUser = userSeries.map((s) => {
        if (s._id === action.series._id) {
          return action.series;
        } else {
          return s;
        }
      });

      return {
        ...state,
        adminSeries: updatedSeriesAdmin,
        userSeries: updatedSeriesUser,
        series: action.series,
        error: "",
      };

    case "DELETE_SERIES":
      let updatedSeries2 = state.adminSeries.filter((s) => {
        return s._id !== action.seriesId;
      });

      let updatedSeriesUser2 = state.userSeries.filter((s) => {
        return s._id !== action.seriesId;
      });

      return {
        ...state,
        error: "",
        adminSeries: updatedSeries2,
        userSeries: updatedSeriesUser2,
        series: null,
      };

    case "ADMIN_GET_SERIES":
      return { ...state, adminSeries: action.adminSeries, error: "" };
    case "USER_GET_ALL_SERIES":
      return { ...state, userSeries: action.userSeries, error: "" };

    case "GET_USER_SERIES":
      return { ...state, userSeries: action.userSeries, error: "" };

    case "GET_SERIES":
      return {
        ...state,
        series: action.series,
        workoutVideos: action.workoutVideos,
        error: "",
      };

    case "SERIES_ERROR":
      return { ...state, error: action.payload };
    case "CLEAR_SERIES_ERROR":
      return { ...state, error: "" };
    default:
      return state;
  }
}
