import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";

import { FaSortUp } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";

import Moment from "react-moment";
import "moment-timezone";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import UploadForm from "../../components/UploadForm/UploadForm";
import { updateVideo } from "../../actions/video";

// Redux Actions
import { getTags, addTag, deleteTag } from "../../actions/tag";

function AdminTags(props) {
  console.log("props: ", props);
  const history = useHistory();

  useEffect(() => {
    if (
      !props.auth.authenticated ||
      (props.auth.user && !props.auth.user.admin)
    ) {
      history.push("/login");
    }
  }, [history, props.auth]);

  const [tags, setTags] = useState([]);
  const [sortDesc, setSortDesc] = useState(true);
  const [limit, setLimit] = useState(100);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    props.dispatch(getTags());
  }, []);

  useEffect(() => {
    if (props.tags && props.tags.length > limit) {
      setTags(props.tags.slice(0, limit));
      setShowMoreButton(true);
    } else {
      setTags(props.tags);
    }
    console.log("Tags: ", tags);
  }, [props.tags, limit]);

  const handleCheck = (e) => {
    console.log("e: ", e.target.value);
    if (e.target.checked) {
      setSelectedTags([e.target.value, ...selectedTags]);
    } else {
      let removedTags = selectedTags.filter((v) => {
        return v != e.target.value;
      });
      setSelectedTags(removedTags);
    }
  };

  // Up arrow means in ascending order
  // Down arrow means in descending order

  const handleSort = () => {
    // If already sorting by this column, flip the order, else default to descending
    if (tags) {
      console.log("hi", sortDesc);
      let sortedTags = tags
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));
      if (sortDesc) {
        setTags(sortedTags.reverse());
      } else {
        setTags(sortedTags);
      }
      setSortDesc(!sortDesc);
    }
  };

  // Handling front end for now, if too many Tags, move search to API
  const searchTags = (val) => {
    if (!val || val === "" || val.length < 1) {
      return;
    } else {
      let results = props.tags.filter((v) => {
        if (v.name.toLowerCase().indexOf(val.toLowerCase()) > -1) {
          return true;
        }
      });
      setTags(results);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    watch,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (formData) => {
    console.log("Here's the formData: ", formData);

    props.dispatch(addTag(formData), () => {
      console.log(formData);
    });
    clearErrors();
    reset();
  };

  const handleDeleteSelection = () => {
    selectedTags.forEach((v) => {
      console.log("deleting Tag: ", v);
      props.dispatch(deleteTag(v));
    });

    setShowDeleteForm(false);
    setSelectedTags([]);
  };

  const showMore = () => {
    setLimit(limit + 50);
  };

  return (
    <div id="admin-tags" className="page">
      <div className="admin-table">
        <div className="page-heading">
          <h2>Tags</h2>
        </div>

        <div className="add-tag-form">
          <form id="add-tag-form" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="tagName">Add a Tag: </label>
              <input
                type="text"
                id="tagName"
                name="name"
                {...register("name")}
                placeholder="Add Tag"
              />
            </div>

            <div>
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>

        {showDeleteForm && (
          <div id="delete-form">
            <p>
              Are you sure you want to delete the selected tags? They will be
              removed from all user videos and series. This action cannot be
              undone.
            </p>

            <div className="button-container">
              <button onClick={() => handleDeleteSelection()}>
                Yes, Delete Tags
              </button>
              <button
                className="cancel"
                onClick={() => setShowDeleteForm(false)}
              >
                No, Cancel
              </button>
            </div>
          </div>
        )}
        <div className="admin-table-toggles">
          {selectedTags.length > 0 && !showDeleteForm && (
            <div className="delete-container">
              <button onClick={() => setShowDeleteForm(true)}>
                Delete Selection
              </button>
            </div>
          )}
        </div>
        <ul id="admin-tags-table-rows" className="admin-table-rows">
          <li className="admin-table-row admin-table-header" key={"0"}>
            <div className="admin-table-column"></div>

            <div className="admin-table-column" onClick={() => handleSort()}>
              Tag {!sortDesc ? <FaSortUp /> : <FaSortDown />}
            </div>
          </li>

          {tags &&
            tags.map((v) => {
              return (
                <li key={v._id} className="admin-table-row">
                  <div className="admin-table-column">
                    <input
                      type="checkbox"
                      value={v._id}
                      onChange={(e) => handleCheck(e)}
                    />
                  </div>

                  <div className="admin-table-column name-column">{v.name}</div>

                  <div className="admin-table-column">
                    <Moment format="MM-DD-YY">{v.createdAt}</Moment>
                  </div>
                </li>
              );
            })}
          {showMoreButton && (
            <li className="row show-more-row">
              <button onClick={() => showMore()}>Show More</button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    tags: state.tag.tags,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AdminTags);
