export default function (state = { error: '', workoutVideo: null }, action) {
    switch (action.type) {
        case "UNAUTHORIZE_USER":
            return { ...state, error: '', workoutVideo: null };
        case "UPDATE_WORKOUT_VIDEO":
            return { ...state, workoutVideo: action.workoutVideo, error: '' }
        case "GET_WORKOUT_VIDEO":
            return { ...state, workoutVideo: action.workoutVideo, error: '' };
        case "WORKOUT_VIDEO_ERROR":
            return { ...state, error: action.payload };
        case "CLEAR_WORKOUT_VIDEO_ERROR":
            return { ...state, error: '' };
        default:
            return state;
    }
}