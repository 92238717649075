import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { connect, useDispatch } from "react-redux";
import Tags from "../../../components/Tags/Tags";
import Select from "react-select";
import { products } from "../../../data/products.json";

// Redux Actions
import { createSeries, updateSeries } from "../../../actions/series";

function SeriesForm(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const { seriesId } = useParams();

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  const [tags, setTags] = useState();
  const handleSetTags = (newTags) => {
    setTags(newTags);
  };

  // Dispatch action
  const onSubmit = (formData) => {
    setIsSubmitting(true);
    formData.tags = tags;
    console.log("selected option: ", selectedOption);

    if (selectedOption !== null) {
      formData.cost = selectedOption.value;
      let productId;
      if (formData.cost && formData.cost !== "0") {
        const product = products.find((item) => item.cost === formData.cost);
        productId = product.productId;
        formData.productId = productId;
      }
    }

    console.log("formData: ", formData);
    if (props.series && seriesId) {
      formData.seriesId = props.seriesId;
      dispatch(
        updateSeries(formData, () => {
          setIsSubmitting(false);
          props.hideForm();
        })
      );
    } else {
      if (props.videos) {
        formData.videos = props.videos;
      }
      console.log("SeriesForm formData: ", formData);
      props.dispatch(
        createSeries(formData, history, () => setIsSubmitting(false))
      );
    }
  };

  useEffect(() => {
    // if it's a new series, set the title to "New Series" and create it.
    let data = {};
    if (props.new) {
      data.title = "New Series";
      data.description = "";
      data.tags = [];
      data.cost = "0";
      data.videos = props.videos ? props.videos : [];
      onSubmit(data);
    }
  }, []);

  useEffect(() => {
    if (props.series?.series && seriesId) {
      setTags(props.series.series.tags);
      setTitle(props.series.series.title);
      setDescription(props.series.series.description);
      if (props.series.series.cost) {
        setSelectedOption({
          value: props.series.series.cost,
          label: props.series.series.cost,
        });
      }
    } else {
      setTags([]);
      setTitle("");
      setDescription("");
    }
  }, [props.series, seriesId]);

  return (
    <form
      id="series-form"
      className="modal-content"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="edit-form-field">
        <label htmlFor="name">Title</label>
        <input
          autoComplete="off"
          defaultValue={title}
          name={"title"}
          {...register("title", { required: true })}
        />
      </div>

      {errors && errors["title"] && <p>Title is required.</p>}

      <div className="edit-form-field">
        <label htmlFor="description">Description</label>
        <textarea
          autoComplete="off"
          className="description-field"
          defaultValue={description}
          name={"description"}
          {...register("description")}
        />
      </div>

      {errors && errors["description"] && <p>Description is required.</p>}

      <div className="edit-form-field">
        <label htmlFor="tags">Tags</label>
        <Tags handleSetTags={handleSetTags} defaultTags={tags} />
      </div>

      <div className="edit-form-field">
        <label htmlFor="cost">Cost</label>
        <Select
          defaultValue={selectedOption}
          name="cost"
          placeholder="0"
          onChange={setSelectedOption}
          options={products}
        />
      </div>

      {props.series && props.series.error && !props.series.error.message && (
        <div className="edit-form-field">
          <p className="form-error-inline">{props.series.error}</p>
        </div>
      )}

      <div className="button-container">
        <button type="submit" disabled={isSubmitting}>
          {seriesId && props.series ? "Save" : "Create"}
        </button>
        <button onClick={() => props.hideForm()}>Cancel</button>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    series: state.series,
  };
};

export default connect(mapStateToProps)(SeriesForm);
