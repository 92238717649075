// 3rd Party Libraries
import { createStore, combineReducers, applyMiddleware } from 'redux';
import jwt_decode from 'jwt-decode';

import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from '../reducers/auth';
import userReducer from '../reducers/user';
import videoReducer from '../reducers/video';
import seriesReducer from '../reducers/series';
import tagReducer from '../reducers/tag';
import workoutVideoReducer from '../reducers/workoutVideo';


import { getAuthUser } from '../actions/auth';

// Creates the Redux store with reducers and middleware
const rootReducer = combineReducers({
    auth: authReducer,
    video: videoReducer,
    user: userReducer,
    series: seriesReducer,
    workoutVideo: workoutVideoReducer,
    tag: tagReducer
})

const persistConfig = {
    key: 'root',
    storage
};

const pReducer = persistReducer(persistConfig, rootReducer);
let middleware;

if (process.env.NODE_ENV === "production") {
    middleware = applyMiddleware(thunk);
} else {
    middleware = applyMiddleware(thunk, logger);
}


const store = createStore(pReducer, middleware);
const persistor = persistStore(store);

// If page is refreshed and token still in local storage, re-authorize user to keep user data in state
const token = localStorage.getItem('token');

if (token) {

    try {
        let decodedToken = jwt_decode(token);

        if (decodedToken) {
            store.dispatch({ type: "AUTHORIZE_USER", userId: decodedToken.userId });
            store.dispatch(getAuthUser());
        }
    } catch (err) {
        console.log(err);
    }

}

export { persistor, store };


