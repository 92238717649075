import React from "react";
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';

// Redux Actions 
import { deleteUser } from '../../../actions/user';
import { unauthorizeUser } from "../../../actions/auth";

function DeleteForm(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const { register, handleSubmit } = useForm({
        mode: "onChange"
    });

    // Dispatch action 
    const onSubmit = (data) => {

        dispatch(deleteUser(props.userId, () => {
            props.hideForm();
            dispatch(unauthorizeUser(() => history.push("/login")));
        }));
    }


    return (
        <form id="delete-form" onSubmit={handleSubmit(onSubmit)}>
            <p>Are you sure you want to delete this account and all its videos? This action cannot be undone!</p>
            <input type="hidden" name="id" {...register("id")} id={props.user._id} />


            {props.auth && props.auth.error && !props.auth.error.message && (
                <div className="edit-form-field">
                    <p className="form-error-inline">{props.auth.error}</p>
                </div>
            )}




            <div className="button-container">
                <button type="submit">Delete Account</button>
            </div>

        </form>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(DeleteForm);