import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useParams, Prompt } from "react-router";
import { List, arrayMove } from "react-movable";
import { connect, useDispatch } from "react-redux";
import { BsArrowRight } from "react-icons/bs";
import ClickOutside from "../../utils/ClickOutside";

import SeriesForm from "./components/SeriesForm";
import DeleteForm from "./components/DeleteForm";
import SeriesItem from "./components/SeriesItem";

import {
  getSeries,
  updateSeries,
  addVideoToSeries,
} from "../../actions/series.js";
import { userGetAllVideos } from "../../actions/video.js";
import { deleteWorkoutVideo } from "../../actions/workoutVideo.js";

function Series(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { seriesId } = useParams();

  const { ref, isComponentVisible, setIsComponentVisible } =
    ClickOutside(false);

  useEffect(() => {
    if (props.auth && !props.auth.authenticated) {
      history.push("/login");
    }
  }, [props.auth, history]);

  const [userVideos, setUserVideos] = useState([]);
  const [series, setSeries] = useState(null);
  const [seriesVideos, setSeriesVideos] = useState([]);
  const [seriesVideoIds, setSeriesVideoIds] = useState([]);

  const [searchResults, setSearchResults] = useState([]);
  const [searchTextValue, setSearchTextValue] = useState("");
  const [showNoResults, setShowNoResults] = useState(false);

  const [seriesUpdated, setSeriesUpdated] = useState(false);
  const [allowReorder, setAllowReorder] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);

  const [min, setMin] = useState();
  const [sec, setSec] = useState();
  useEffect(() => {
    if (seriesId) {
      dispatch(getSeries(seriesId));
    }
  }, [seriesId, dispatch]);

  useEffect(() => {
    if (props.series) {
      setSeries(props.series);
      setSeriesVideos(props.series.videos);
      let idList = [];
      props.series.videos.forEach((video) => idList.push(video.video._id));
      setSeriesVideoIds(idList);
    }
  }, [props.series]);

  useEffect(() => {
    if (seriesUpdated) {
      dispatch(
        updateSeries({ videos: seriesVideos, seriesId: props.series._id })
      );
      setSeriesUpdated(false);
    }
  }, [seriesUpdated, dispatch, props.series, seriesVideos]);

  useEffect(() => {
    if (props.auth.user) {
      dispatch(userGetAllVideos(props.auth.user._id));
    }
  }, [props.auth, dispatch]);

  useEffect(() => {
    if (props.videos && props.auth.user) {
      if (props.videos.userVideos && !props.auth.user.admin) {
        setUserVideos(props.videos.userVideos);
      } else if (props.videos.adminVideos && props.auth.user.admin) {
        setUserVideos(props.videos.adminVideos);
      }
    }
  }, [props.videos, props.auth]);

  useEffect(() => {
    if (series) {
      let seriesMin = Math.floor(series.duration / 60);
      let seriesSec = series.duration - seriesMin * 60;
      setMin(seriesMin);
      setSec(seriesSec);
    }
  }, [series]);

  const redirect = () => {
    if (props.auth.user.admin) {
      history.push("/admin/series");
    } else {
      history.push("/library");
    }
  };

  const addToSeries = (video) => {
    // setSearchResults([]);
    // setSearchTextValue("");
    setShowNoResults(false);

    let currentDuration = sec + min * 60;
    let newDuration = currentDuration + video.duration;

    let seriesMin = Math.floor(newDuration / 60);
    let seriesSec = newDuration - seriesMin * 60;

    setMin(seriesMin);
    setSec(seriesSec);

    props.dispatch(
      addVideoToSeries({
        seriesId: series._id,
        videoId: video._id,
      })
    );
  };

  const removeFromSeries = (video, workoutVideo) => {
    props.dispatch(
      deleteWorkoutVideo(
        { videoId: video._id, seriesId: props.series._id },
        () => {
          props.dispatch(updateSeries({ seriesId: props.series._id }));
        }
      )
    );
  };

  const handleReorder = () => {
    setAllowReorder(false);

    dispatch(
      updateSeries({ videos: seriesVideos, seriesId: props.series._id })
    );
  };

  const handleClickEmptySearchField = () => {
    setSearchResults(userVideos);
    setShowNoResults(false);
    setIsComponentVisible(true);
  };

  const searchVideos = (val) => {
    setIsComponentVisible(true);
    if (!val || val === "" || val.length < 1) {
      setSearchResults(userVideos);
      setShowNoResults(false);
      return;
    } else {
      let results = userVideos.filter((v) => {
        if (
          v.title.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          v.description.toLowerCase().indexOf(val.toLowerCase()) > -1
        ) {
          if (
            v.owner == series.owner ||
            (props.auth.user.admin && v.isBallerized)
          ) {
            return true;
          } else {
            return false;
          }
        }
      });

      if (results.length === 0) {
        setShowNoResults(true);
      } else {
        setShowNoResults(false);
      }
      setSearchResults(results);
    }
  };

  if (series && seriesId) {
    console.log("seriesVideoIds", seriesVideoIds);
    return (
      <div id="series" className="page">
        <Prompt
          when={series.title === "New Series" && showDeleteForm}
          message="Please give your series a title. It cannot be named 'New Series'."
        />
        <div className="page-heading">
          <h2>{series.title}</h2>
        </div>
        <div className="description">
          <h3>
            Duration: {min} min {sec} sec
          </h3>
          <h4>Cost: ${series.cost}</h4>
        </div>
        <div className="description">{series.description}</div>

        {series.tags && (
          <div className="tags">
            {series.tags.map((tag) => {
              return <div key={tag._id}>{tag.name}</div>;
            })}
          </div>
        )}

        <div className="action-button-container">
          {!showEditForm && !showDeleteForm && (
            <button className="edit" onClick={() => setShowEditForm(true)}>
              Edit Details
            </button>
          )}
          {!showDeleteForm && !showEditForm && (
            <button
              className="complete"
              onClick={() => {
                if (allowReorder) {
                  handleReorder();
                }
                if (series.title === "New Series") {
                  alert(
                    "Please give your series a title. It cannot be named 'New Series'."
                  );
                } else {
                  history.goBack();
                }
              }}
            >
              Save Series
            </button>
          )}
          {!showDeleteForm && !showEditForm && (
            <button className="delete" onClick={() => setShowDeleteForm(true)}>
              Delete Series
            </button>
          )}
        </div>

        {showEditForm && !showDeleteForm && (
          <SeriesForm
            series={series}
            seriesId={series._id}
            hideForm={() => setShowEditForm(false)}
          />
        )}

        {showDeleteForm && !showEditForm && (
          <DeleteForm
            series={series}
            seriesId={series._id}
            hideForm={() => setShowDeleteForm(false)}
            redirect={redirect}
          />
        )}

        <div className="search-form">
          <p>Click below to search or add videos</p>
          <input
            autoFocus={true}
            onFocus={() => {
              handleClickEmptySearchField();
            }}
            placeholder="Search videos . . . "
            value={searchTextValue}
            onClick={() => {
              handleClickEmptySearchField();
            }}
            onChange={(e) => {
              searchVideos(e.target.value);
              setSearchTextValue(e.target.value);
            }}
          />
          <button onClick={handleClickEmptySearchField}>Show All</button>
        </div>

        {!allowReorder && !isComponentVisible && (
          <button id="reorder" onClick={() => setAllowReorder(true)}>
            Reorder Videos
          </button>
        )}

        {allowReorder && !isComponentVisible && (
          <a
            className="reorder"
            onClick={() => {
              handleReorder();
            }}
          >
            <button>Save Order</button>
          </a>
        )}

        {isComponentVisible && (
          <div className="search-results">
            <div className="search-results-inner" ref={ref}>
              {showNoResults && (
                <div className="item">
                  <div className="title">No Results Found</div>
                  <div className="title">
                    <NavLink to="/library">
                      Upload Videos <BsArrowRight />
                    </NavLink>
                  </div>
                </div>
              )}

              {searchResults &&
                searchResults.map((r) => {
                  return (
                    <div key={r._id} className="item">
                      <div
                        className="thumbnail"
                        style={{ backgroundImage: `url(${r.thumbnailUrl})` }}
                      ></div>
                      <div className="search-results-info">
                        <div className="title">{r.title}</div>
                        <div className="description">{r.description}</div>
                      </div>
                      <div className="action" onClick={() => addToSeries(r)}>
                        {seriesVideoIds.includes(r._id) ? "Add Again" : "Add"}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {allowReorder && seriesVideos && (
          <List
            values={seriesVideos}
            onChange={({ oldIndex, newIndex }) =>
              setSeriesVideos(arrayMove(seriesVideos, oldIndex, newIndex))
            }
            renderList={({ children, props }) => (
              <div className="series-container" {...props}>
                {children}
              </div>
            )}
            renderItem={({ value, props }) => {
              return (
                <div className="series-item draggable" {...props}>
                  <div className="series-item-number">
                    {props.key + 1 ? props.key + 1 : ""}
                  </div>

                  <div
                    className="thumbnail"
                    style={{
                      backgroundImage: `url(${value.video.thumbnailUrl})`,
                    }}
                  ></div>

                  <div className="series-item-info">
                    <div className="title">
                      <h4>{value.video.title}</h4>
                    </div>
                    {/* <TimerForm videoId={value._id} simple={true} /> */}
                    <div className="desc">{value.video.description}</div>
                  </div>
                </div>
              );
            }}
          />
        )}

        {!allowReorder &&
          seriesVideos.map((video) => {
            return (
              <SeriesItem
                {...props}
                key={video._id}
                video={video}
                simple={false}
                removeFromSeries={removeFromSeries}
              />
            );
          })}
      </div>
    );
  } else {
    return (
      <div id="series" className="page">
        <div className="page-heading">
          <h2>Create a New Series</h2>
        </div>
        <SeriesForm
          series={props.series}
          hideForm={() => setShowEditForm(false)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    series: state.series.series,
    videos: state.video,
  };
};

export default connect(mapStateToProps)(Series);
