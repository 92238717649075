import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import Select from "react-select";

// Redux Actions
import { updateWorkoutVideo } from "../../../actions/workoutVideo";
import { updateSeries } from "../../../actions/series.js";

function TimerForm(props) {
  const [showForm, setShowForm] = useState(false);
  const [minOptions, setMinOptions] = useState({});
  const [secOptions, setSecOptions] = useState({});

  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();

  const { handleSubmit, control } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (props.series && props.video && props.series.series) {
      let min = Math.floor(props.video.duration / 60);
      let sec = props.video.duration - min * 60;
      setMinutes(min);
      if (!Number.isNaN(sec)) {
        setSeconds(sec);
      }
    }
  }, [props.series, props.video]);

  useEffect(() => {
    let min = [];
    let sec = [];
    for (let i = 0; i < 59; i++) {
      min.push({
        label: i,
        value: i,
      });

      sec.push({
        label: i,
        value: i,
      });
    }
    setMinOptions(min);
    setSecOptions(sec);
  }, []);

  // Dispatch action
  const onSubmit = (formData) => {
    formData.workoutVideoId = props.video._id;

    props.dispatch(
      updateWorkoutVideo(formData, () => {
        setMinutes(formData.min.value);
        setSeconds(formData.sec.value);
        setShowForm(false);

        props.dispatch(updateSeries({ seriesId: props.series.series._id }));
      })
    );
  };

  return (
    <form id="timer-form" onSubmit={handleSubmit(onSubmit)}>
      {props.series && props.series.error && !props.series.error.message && (
        <p className="form-error">{props.series.error}</p>
      )}

      <div className="timer">
        {showForm && props.video && (
          <>
            <div className="timer-item">
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    options={minOptions}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                )}
                control={control}
                name="min"
                defaultValue={{ label: minutes, value: minutes }}
              />

              <label>min</label>
            </div>
            <div className="timer-item">
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    options={secOptions}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                )}
                control={control}
                name="sec"
                defaultValue={{ label: seconds, value: seconds }}
              />

              <label>sec</label>
            </div>

            <div className="action-link">
              <button type="submit">Done</button>
            </div>
          </>
        )}

        {!showForm && props.video && (
          <>
            <div className="timer-item">
              <div>{minutes}</div>
              <label>min</label>
            </div>

            <div className="timer-item">
              <div>{seconds}</div>
              <label>sec</label>
            </div>
          </>
        )}
        {!props.simple && !showForm && (
          <div className="action-link">
            <button
              type="button"
              onClick={() => {
                setShowForm(true);
              }}
            >
              Change Duration
            </button>
          </div>
        )}
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    series: state.series,
  };
};

export default connect(mapStateToProps)(TimerForm);
