import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import ReactPlayer from "react-player";
import EditForm from "./components/EditForm";
import DeleteForm from "./components/DeleteForm";
import Moment from "react-moment";
import "moment-timezone";

// Redux Actions
import { getAuthUser } from "../../actions/auth";

function Account(props) {
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [user, setUser] = useState(props.auth.user);
  const [showEmailMessage, setShowEmailMessage] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuthUser());
  }, [showEditForm]);

  useEffect(() => {
    setUser(props.auth.user);
  }, [props.auth.user]);

  const emailChanged = () => {
    setShowEmailMessage(true);
  };

  if (user) {
    return (
      <div id="user" className="page">
        <div className="user-title">
          <h2>{user.username}</h2>
          <div
            className="avatar"
            style={{ backgroundImage: `url(${user.avatar})` }}
          ></div>
        </div>

        <div className="action-button-container">
          {!showEditForm && !showDeleteForm && (
            <button className="edit" onClick={() => setShowEditForm(true)}>
              Edit Details
            </button>
          )}
          {!showDeleteForm && !showEditForm && (
            <button className="delete" onClick={() => setShowDeleteForm(true)}>
              Delete Account
            </button>
          )}
        </div>

        {showEditForm && !showDeleteForm && (
          <EditForm
            user={user}
            hideForm={() => setShowEditForm(false)}
            emailChanged={() => emailChanged()}
          />
        )}

        {showDeleteForm && !showEditForm && (
          <DeleteForm
            user={user}
            userId={user._id}
            hideForm={() => setShowDeleteForm(false)}
          />
        )}

        {!showEditForm && (
          <div className="user-details">
            <div className="user-details-item">
              <div className="name">Name</div>
              <div className="details">{user.username}</div>
            </div>

            <div className="user-details-item">
              <div className="name">Email</div>
              <div className="details">{user.email}</div>
            </div>

            {showEmailMessage && (
              <div className="user-details-item">
                <div className="name"></div>
                <div className="details check-email">
                  Please check your{" "}
                  <em>{props.auth.user.changeEmailAwaitingConfirmation}</em>{" "}
                  inbox for a link to confirm your email address change.
                </div>
              </div>
            )}

            <div className="user-details-item">
              <div className="name">Joined</div>
              <div className="details">
                <Moment format="MM-DD-YY">{new Date(user.createdAt)}</Moment>
              </div>
            </div>

            <div className="user-details-item">
              <div className="name">Bio</div>
              <div className="details">{user.bio}</div>
            </div>
          </div>
        )}

        <div className="user-preview">
          <ReactPlayer
            controls="true"
            width="100%"
            height="100%"
            url={user.url}
          />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Account);
