import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";

import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import Logo from "../../images/ballerized-full-logo.png";

import { unauthorizeUser } from "../../actions/auth";

function AdminSidebar(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showMenu, setShowMenu] = useState(true);

  const handleLogOut = () => {
    dispatch(unauthorizeUser(() => history.push("/login")));
  };

  return (
    <div id="sidebar" className={`${showMenu ? "sidebar active" : "sidebar"}`}>
      <div onClick={() => setShowMenu(!showMenu)} className="menu-icon">
        {showMenu && <MdClose />}

        {!showMenu && <GiHamburgerMenu />}
      </div>

      <div className="logo-container">
        <img alt="Ballerized Logo" src={Logo} />
      </div>

      <div className="sidebar-menu">
        <NavLink to="/account" onClick={() => setShowMenu(false)}>
          Your Account
        </NavLink>

        <NavLink to="/admin/videos" onClick={() => setShowMenu(false)}>
          Videos
        </NavLink>
        <NavLink to="/admin/series" onClick={() => setShowMenu(false)}>
          Series
        </NavLink>
        <NavLink to="/admin/tags" onClick={() => setShowMenu(false)}>
          Tags
        </NavLink>
        <NavLink to="/admin/users" onClick={() => setShowMenu(false)}>
          Users
        </NavLink>

        <a href="/login" onClick={handleLogOut}>
          Log Out
        </a>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(AdminSidebar);
