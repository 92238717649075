import React from "react";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";

// Redux Actions
import { updateUser } from "../../../actions/user";

function EditForm(props) {
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  console.log(props.user);

  // Dispatch action
  const onSubmit = (data) => {
    data.id = props.user._id;

    dispatch(
      updateUser(data, () => {
        props.hideForm();
      })
    );
  };

  return (
    <form id="edit-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="edit-form-field">
        <label htmlFor="username">Username</label>
        <input
          autoComplete="off"
          defaultValue={props.user.username}
          name={"username"}
          {...register("username")}
        />
      </div>

      <div className="edit-form-field">
        <label htmlFor="email">Email</label>
        <input
          autoComplete="off"
          defaultValue={props.user.email}
          name={"email"}
          {...register("email", { required: true })}
        />
      </div>

      {errors && errors["email"] && <p>Email address is required.</p>}

      <div className="edit-form-field">
        <label htmlFor="bio">Bio</label>
        <textarea
          autoComplete="off"
          defaultValue={props.user.bio}
          name={"bio"}
          {...register("bio")}
        />
      </div>

      {props.userState &&
        props.userState.error &&
        !props.userState.error.message && (
          <div className="edit-form-field">
            <p className="form-error-inline">{props.userState.error}</p>
          </div>
        )}

      <div className="button-container">
        <button type="submit">Save</button>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    userState: state.user,
  };
};

export default connect(mapStateToProps)(EditForm);
