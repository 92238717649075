export default function (state = { error: "", tags: [] }, action) {
  switch (action.type) {
    case "UNAUTHORIZE_USER":
      return { ...state, error: "", tags: [] };
    case "GET_TAGS":
      return { ...state, tags: action.tags };
    case "ADD_TAG":
      return { ...state, tags: [...state.tags, action.tag] };
    case "DELETE_TAG":
      return {
        ...state,
        tags: state.tags.filter((tag) => tag._id !== action.tag),
      };
    case "TAG_ERROR":
      return { ...state, error: action.payload };
    case "CLEAR_TAG_ERROR":
      return { ...state, error: "" };
    default:
      return state;
  }
}
