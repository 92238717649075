import React from "react";
import { useForm } from "react-hook-form";

import { connect, useDispatch } from "react-redux";

// Redux Actions
import { getAuthUser } from "../../../actions/auth";
import { updateUser } from "../../../actions/user";

function EditForm(props) {
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (data) => {
    data.id = props.user._id;

    if (data.email !== props.user.email) {
      props.emailChanged();
    }

    dispatch(
      updateUser(data, () => {
        dispatch(getAuthUser());
        props.hideForm();
      })
    );
  };

  return (
    <form id="edit-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="edit-form-field">
        <label htmlFor="username">Username</label>
        <input
          autoComplete="off"
          defaultValue={props.user.username}
          name={"username"}
          {...register("username")}
        />
      </div>

      <div className="edit-form-field">
        <label htmlFor="email">Email</label>
        <input
          autoComplete="off"
          defaultValue={props.user.email}
          name={"email"}
          {...register("email", { required: true })}
        />
      </div>

      {errors && errors["email"] && <p>Email address is required.</p>}

      <div className="edit-form-field">
        <label htmlFor="bio">Bio</label>
        <textarea
          autoComplete="off"
          defaultValue={props.user.bio}
          name={"bio"}
          {...register("bio")}
        />
      </div>

      {props.auth && props.auth.error && !props.auth.error.message && (
        <div className="edit-form-field">
          <p className="form-error-inline">{props.auth.error}</p>
        </div>
      )}

      <div className="button-container">
        <button type="submit">Save</button>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(EditForm);
