import React from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { NavLink, useHistory } from "react-router-dom";
import ClickOutside from "../../../../utils/ClickOutside";

import { connect } from "react-redux";
import { addVideoToSeries } from "../../../../actions/series.js";

function Title(props) {
  const history = useHistory();

  const { ref, isComponentVisible, setIsComponentVisible } =
    ClickOutside(false);

  const handleAddToSeries = (series) => {
    props.dispatch(
      addVideoToSeries({
        seriesId: series._id,
        videoId: props.video._id,
      })
    );

    setIsComponentVisible(false);
    history.push(`/series/${series._id}`);
  };

  return (
    <>
      <div className="title">
        {props.video.title}

        <div className="more" onClick={() => setIsComponentVisible(true)}>
          <FiMoreHorizontal />
        </div>
      </div>

      {isComponentVisible && (
        <div className="menu" ref={ref}>
          <NavLink to={`/video/${props.video._id}`} className="menu-item">
            Go to Video
          </NavLink>

          {props.series &&
            props.series.userSeries.map((s) => {
              return (
                <div
                  key={s._id}
                  className="menu-item"
                  onClick={() => handleAddToSeries(s)}
                >
                  Add to {s.title}
                </div>
              );
            })}
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    series: state.series,
  };
};

export default connect(mapStateToProps)(Title);
