import React from "react";
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';

// Redux Actions 
import { deleteUser } from '../../../actions/user';

function DeleteForm(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const { register, handleSubmit } = useForm({
        mode: "onChange"
    });


    // Dispatch action 
    const onSubmit = (data) => {
        dispatch(deleteUser(props.userId, () => {
            props.hideForm();
            if (props.auth.user && props.auth.user.admin) {
                history.push('/admin/users');
            }
        }));
    }



    return (
        <form id="delete-form" onSubmit={handleSubmit(onSubmit)}>
            <p>Are you sure you want to delete this account and all its videos? This action cannot be undone!</p>



            {props.user && props.user.error && !props.user.error.message && (
                <div className="edit-form-field">
                    <p className="form-error-inline">{props.user.error}</p>
                </div>
            )}


            <input type="hidden" name="id" {...register("id")} id={props.user._id} />
            <div className="button-container">
                <button type="submit">Delete Account</button>
                <button className="cancel" onClick={() => props.hideForm()}>No, Cancel</button>
            </div>

        </form>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        auth: state.auth
    }
}

export default connect(mapStateToProps)(DeleteForm);