import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";

import { FaSortUp } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";

import Moment from "react-moment";
import "moment-timezone";
import NewUserForm from "./components/NewUserForm";

import { connect } from "react-redux";

// Redux Actions
import { adminGetUsers, deleteUser, updateUser } from "../../actions/user";

function AdminUsers(props) {
  const history = useHistory();

  useEffect(() => {
    if (
      !props.auth.authenticated ||
      (props.auth.user && !props.auth.user.admin)
    ) {
      history.push("/login");
    }
  }, [props.auth]);

  const [users, setUsers] = useState();
  const [sortBy, setSortBy] = useState({ key: "createdAt", desc: true });
  const [limit, setLimit] = useState(100);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);

  useEffect(() => {
    props.dispatch(adminGetUsers(sortBy));
  }, [sortBy]);

  useEffect(() => {
    if (props.users && props.users.length > limit) {
      setUsers(props.users.slice(0, limit));
      setShowMoreButton(true);
    } else {
      setUsers(props.users);
    }
  }, [props.users, limit]);

  const handleCheck = (e) => {
    if (e.target.checked) {
      setSelectedUsers([e.target.value, ...selectedUsers]);
    } else {
      let removedUsers = selectedUsers.filter((v) => {
        return v != e.target.value;
      });
      setSelectedUsers(removedUsers);
    }
  };

  // Up arrow means in ascending order
  // Down arrow means in descending order

  const handleSort = (column) => {
    // If already sorting by this column, flip the order, else default to descending
    if (sortBy.key === column) {
      setSortBy({ key: column, desc: !sortBy.desc });
    } else {
      setSortBy({ key: column, desc: true });
    }
  };

  // Handling front end for now, if too many users, move search to API
  const searchUsers = (val) => {
    if (!val || val === "" || val.length < 1) {
      return;
    } else {
      let results = props.users.filter((u) => {
        if (
          u.username.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          u.email.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          (u.firstName &&
            u.firstName.toLowerCase().indexOf(val.toLowerCase()) > -1) ||
          (u.lastName &&
            u.lastName.toLowerCase().indexOf(val.toLowerCase()) > -1)
        ) {
          return true;
        }
      });
      setUsers(results);
    }
  };

  const handleDeleteSelection = () => {
    selectedUsers.forEach((u) => {
      props.dispatch(
        deleteUser(u, () => {
          props.dispatch(adminGetUsers(sortBy));
        })
      );
    });
    setSelectedUsers([]);
    setShowDeleteForm(false);
  };

  const handleToggleAdmin = () => {
    console.log("Selected Videos: ", selectedUsers);
    selectedUsers.forEach((u) => {
      props.dispatch(updateUser({ id: u, toggleAdmin: true }, () => {}));
    });
    setSelectedUsers([]);
  };

  const showMore = () => {
    setLimit(limit + 50);
  };

  return (
    <div id="admin-users" className="page">
      <div className="admin-table">
        <div className="page-heading">
          <h2>Users</h2>
        </div>

        {!showCreateForm && (
          <div className="action-button-container">
            <button onClick={() => setShowCreateForm(true)}>
              <AiOutlinePlus /> New Admin
            </button>
          </div>
        )}

        {showCreateForm && (
          <NewUserForm hideForm={() => setShowCreateForm(false)} />
        )}

        <div className="search-form">
          <input
            placeholder="Search Users"
            onChange={(e) => searchUsers(e.target.value)}
          />
        </div>

        {showDeleteForm && (
          <div id="delete-form">
            <p>
              Are you sure you want to delete the selected users? This will also
              remove all of the users' series and videos. This action cannot be
              undone.
            </p>

            <div className="button-container">
              <button onClick={() => handleDeleteSelection()}>
                Yes, Delete Users
              </button>
              <button
                className="cancel"
                onClick={() => setShowDeleteForm(false)}
              >
                No, Cancel
              </button>
            </div>
          </div>
        )}
        <div className="admin-table-toggles">
          {selectedUsers.length > 0 && !showDeleteForm && (
            <div className="delete-container">
              <button onClick={() => setShowDeleteForm(true)}>
                Delete Selection
              </button>
            </div>
          )}
          {selectedUsers.length > 0 && (
            <div className="toggle-container">
              <button onClick={() => handleToggleAdmin()}>Toggle Admin</button>
            </div>
          )}
        </div>
        <ul id="admin-users-table-rows" className="admin-table-rows">
          <li className="admin-table-row admin-table-header">
            <div className="admin-table-column"></div>

            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("username");
              }}
            >
              Username{" "}
              {sortBy.key === "username" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "username" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>

            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("email");
              }}
            >
              Email{" "}
              {sortBy.key === "email" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "email" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>

            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("admin");
              }}
            >
              Admin{" "}
              {sortBy.key === "admin" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "admin" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>

            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("athlete");
              }}
            >
              Athlete{" "}
              {sortBy.key === "athlete" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "athlete" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>

            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("instructor");
              }}
            >
              Instructor{" "}
              {sortBy.key === "instructor" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "instructor" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>

            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("joined");
              }}
            >
              Joined{" "}
              {sortBy.key === "joined" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "joined" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>
          </li>

          {users &&
            users.map((u) => {
              return (
                <li className="admin-table-row" key={u._id}>
                  <div className="admin-table-column">
                    <input
                      type="checkbox"
                      value={u._id}
                      onChange={(e) => handleCheck(e)}
                    />
                  </div>

                  <div className="admin-table-column">
                    <NavLink to={`/user/${u._id}`}>{u.username}</NavLink>
                  </div>

                  <div className="admin-table-column">{u.email}</div>

                  <div className="admin-table-column">
                    {u.admin ? <FaCheck /> : ""}
                  </div>

                  <div className="admin-table-column">
                    {u.athlete ? <FaCheck /> : ""}
                  </div>

                  <div className="admin-table-column">
                    {u.instructor ? <FaCheck /> : ""}
                  </div>

                  <div className="admin-table-column">
                    <Moment format="MM-DD-YY">{u.createdAt}</Moment>
                  </div>
                </li>
              );
            })}
          {showMoreButton && (
            <li className="row show-more-row">
              <button onClick={() => showMore()}>Show More</button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    users: state.user.adminUsers,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AdminUsers);
