import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import Tags from "../../../components/Tags/Tags";

// Redux Actions
import { updateVideo } from "../../../actions/video";

function EditForm(props) {
  console.log("EditForm props: ", props);

  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  const [tags, setTags] = useState();

  // Dispatch action
  const onSubmit = (formData) => {
    formData.id = props.video._id;
    formData.tags = tags;

    props.dispatch(
      updateVideo(formData, () => {
        props.hideForm();
      })
    );
  };

  const handleSetTags = (newTags) => {
    setTags(newTags);
  };

  return (
    <form id="edit-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="edit-form-field">
        <label htmlFor="name">Title</label>
        <input
          autoComplete="off"
          defaultValue={props.video.title}
          name={"title"}
          {...register("name", { required: true })}
        />
      </div>

      <div className="edit-form-field">
        <label htmlFor="tags">Tags</label>
        <Tags defaultTags={props.video.tags} handleSetTags={handleSetTags} />
      </div>

      <div className="edit-form-field">
        <label htmlFor="description">Short Description</label>
        <textarea
          autoComplete="off"
          className="description-field"
          defaultValue={props.video.description}
          name={"description"}
          {...register("description", { required: true })}
        />
      </div>

      {props.videoState &&
        props.videoState.error &&
        !props.videoState.error.message && (
          <div className="edit-form-field">
            <p className="form-error-inline">{props.videoState.error}</p>
          </div>
        )}

      <div className="button-container">
        <button type="submit">Save</button>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    videoState: state.video,
  };
};

export default connect(mapStateToProps)(EditForm);
