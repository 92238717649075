import React, { useState, useEffect } from "react";
import { RiCloseFill } from "react-icons/ri";
import { connect, useDispatch } from "react-redux";

import ClickOutside from "../../utils/ClickOutside";

import { getTags } from "../../actions/tag";

function Tags(props) {
  const dispatch = useDispatch();

  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [matches, setMatches] = useState([]);
  const [tagsError, setTagsError] = useState(null);
  const [textInput, setTextInput] = useState("");

  const { ref, isComponentVisible, setIsComponentVisible } = ClickOutside(
    false
  );

  const addTag = tag => {
    setTags([...tags, tag]);
    setTextInput("");
    setMatches([]);
  };

  // Initialize with default tags if available (editing entry)
  useEffect(() => {
    if (props.defaultTags) {
      setTags(props.defaultTags);
    }
  }, [props.defaultTags]);

  useEffect(() => {
    dispatch(getTags());
  }, []);

  useEffect(() => {
    if (props.tag.tags) {
      setSuggestions(props.tag.tags);
    }
  }, [props.tag]);

  useEffect(() => {
    props.handleSetTags(tags);
  }, [tags]);

  const removeTag = tag => {
    let index = tags.findIndex(t => {
      return t.name == tag;
    });

    let newTags = [...tags.slice(0, index), ...tags.slice(index + 1)];
    setTags(newTags);
  };

  useEffect(() => {
    props.handleSetTags(tags);
  }, [tags]);

  const handleInput = e => {
    setTagsError(null);

    setTextInput(e.target.value);
    let text = e.target.value.toLowerCase();
    let suggestionMatches = [];

    setIsComponentVisible(true);

    if (!suggestions) {
      return;
    } else {
      suggestions.forEach(suggestion => {
        if (suggestion.name && suggestion.name.toLowerCase().includes(text)) {
          suggestionMatches = [...suggestionMatches, suggestion];
        }
      });

      // Remove already selected tags from the list
      suggestionMatches = suggestionMatches.filter(match => {
        let found = tags.find(tag => tag.name == match.name);
        if (!found) {
          return match;
        }
      });

      setMatches(suggestionMatches);

      if (text.length > 0 && suggestionMatches.length === 0) {
        setTagsError("No Tags Found");
      }
    }
  };

  return (
    <div id="tags-form-container">
      {tags &&
        tags.map(tag => {
          return (
            <div key={tag.name} className="tag">
              <span>{tag.name}</span>
              <RiCloseFill onClick={() => removeTag(tag.name)} />
            </div>
          );
        })}

      <input type="text" name="tag" value={textInput} onChange={handleInput} />

      {isComponentVisible && (
        <div className="suggestions-container" ref={ref}>
          <ul className="suggestions">
            {tagsError && (
              <li className="suggestion" key="none">
                {tagsError}
              </li>
            )}

            {matches.length > 0 &&
              matches.map(match => {
                return (
                  <li
                    key={match.name}
                    className="suggestion"
                    onClick={() => addTag(match)}
                  >
                    {match.name}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    tag: state.tag
  };
};

export default connect(mapStateToProps)(Tags);
