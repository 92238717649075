import axios from "axios";
import config from "../config/keys";

const ROOT = config.rootUrl;

export function getTags(sortBy) {
  return function (dispatch) {
    axios
      .get(`${ROOT}/tags`, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(tagError(response.data.error));
        } else {
          dispatch(clearTagError());
          dispatch({ type: "GET_TAGS", tags: response.data.tags });
        }
      })
      .catch((error) => {
        dispatch(tagError("There was an error fetching tags."));
      });
  };
}

export function addTag(data, callback) {
  return function (dispatch) {
    axios
      .post(`${ROOT}/admin/tags/new`, data, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(tagError(response.data.error));
        } else {
          console.log("Tag added:", response.data.tag);
          dispatch(clearTagError());
          dispatch({ type: "ADD_TAG", tag: response.data.tag });
          if (callback) {
            callback();
          }
        }
      })
      .catch((error) => {
        dispatch(tagError("There was an error fetching tags."));
      });
  };
}

export function deleteTag(tagId, callback) {
  return function (dispatch) {
    axios
      .delete(`${ROOT}/admin/tags/${tagId}`, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(tagError(response.data.error));
        } else {
          console.log("Tag deleted:", response.data);
          dispatch(clearTagError());
          dispatch({ type: "DELETE_TAG", tag: tagId });
          if (callback) {
            callback();
          }
        }
      })
      .catch((error) => {
        dispatch(tagError("There was an error fetching tags."));
      });
  };
}

export function tagError(error) {
  return {
    type: "TAG_ERROR",
    payload: error,
  };
}

export function clearTagError() {
  return {
    type: "CLEAR_TAG_ERROR",
  };
}
