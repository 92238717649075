import React, { useState } from "react";
import { useForm } from 'react-hook-form';

import { useDispatch } from 'react-redux';

import Logo from "../../images/ballerized-full-logo.png";

// Redux Actions 
import { clearAuthError, forgotPassword } from '../../actions/auth';

function ForgotPassword(props) {

    const dispatch = useDispatch();


    const { register, handleSubmit } = useForm({
        mode: "onChange"
    });

    const [showForm, setShowForm] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);


    // Dispatch action 
    const onSubmit = (formData) => {
        dispatch(forgotPassword(formData, () => {
            dispatch(clearAuthError());
            setShowForm(false);
            setShowConfirmation(true);


        }));
    }

    return (
        <div id="page-login" className="auth">

            <div className="auth-form-container">

                <div className="auth-logo-container"><img alt="Logo" className="auth-logo" src={Logo} /></div>

                {
                    showConfirmation &&
                    <div>Please check your email for a link to reset your password.</div>
                }

                {showForm && !showConfirmation &&

                    <div className="auth-form">
                        <h3>Forgot your password?</h3>

                        {props.auth && props.auth.error && !props.auth.error.message && (
                            <p className="form-error">{props.auth.error}</p>
                        )}


                        <form id="login-form" onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-row">
                                <div className="form-input-container">
                                    <label htmlFor="email">Email</label>
                                    <input name="email" {...register('email')} />
                                </div>
                            </div>

                            <div className="button-container">
                                <button type="submit">Request Password Reset</button>
                            </div>

                        </form>

                    </div>
                }

            </div>



        </div>
    );

}


export default ForgotPassword;