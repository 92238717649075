import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";

import { FaSortUp } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";

import Moment from "react-moment";
import "moment-timezone";

import { connect } from "react-redux";

import UploadForm from "../../components/UploadForm/UploadForm";
import { updateVideo } from "../../actions/video";

// Redux Actions
import { adminGetVideos, deleteVideo } from "../../actions/video";
import SeriesCreateModal from "../Library/components/SeriesCreateModal";

function AdminVideos(props) {
  console.log("props: ", props);
  const history = useHistory();

  useEffect(() => {
    if (
      !props.auth.authenticated ||
      (props.auth.user && !props.auth.user.admin)
    ) {
      history.push("/login");
    }
  }, [props.auth]);

  const [videos, setVideos] = useState([]);
  const [sortBy, setSortBy] = useState({ key: "createdAt", desc: true });
  const [limit, setLimit] = useState(100);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    props.dispatch(adminGetVideos(sortBy));
  }, [sortBy]);

  useEffect(() => {
    if (props.videos && props.videos.length > limit) {
      setVideos(props.videos.slice(0, limit));
      setShowMoreButton(true);
    } else {
      setVideos(props.videos);
    }
  }, [props.videos, limit]);

  const handleCheck = (e) => {
    if (e.target.checked) {
      setSelectedVideos([e.target.value, ...selectedVideos]);
    } else {
      let removedVideos = selectedVideos.filter((v) => {
        return v != e.target.value;
      });
      setSelectedVideos(removedVideos);
    }
  };

  // Up arrow means in ascending order
  // Down arrow means in descending order

  const handleSort = (column) => {
    // If already sorting by this column, flip the order, else default to descending
    if (sortBy.key === column) {
      setSortBy({ key: column, desc: !sortBy.desc });
    } else {
      setSortBy({ key: column, desc: true });
    }
  };

  // Handling front end for now, if too many videos, move search to API
  const searchVideos = (val) => {
    if (!val || val === "" || val.length < 1) {
      return;
    } else {
      let results = props.videos.filter((v) => {
        if (
          v.title.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          v.description.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          (v.username &&
            v.username.toLowerCase().indexOf(val.toLowerCase()) > -1) ||
          v.tags
            .flatMap((r) => r.name)
            .join()
            .toLowerCase()
            .indexOf(val.toLowerCase()) > -1
        ) {
          return true;
        }
      });
      setVideos(results);
    }
  };

  const handleDeleteSelection = () => {
    selectedVideos.forEach((v) => {
      props.dispatch(
        deleteVideo(v, () => {
          props.dispatch(adminGetVideos(sortBy));
        })
      );
    });
    setSelectedVideos([]);
    setShowDeleteForm(false);
  };

  const handleToggleFeatured = () => {
    selectedVideos.forEach((v) => {
      props.dispatch(updateVideo({ id: v, toggleFeatured: true }, () => {}));
    });
    setSelectedVideos([]);
  };

  const handleToggleHidden = () => {
    selectedVideos.forEach((v) => {
      props.dispatch(updateVideo({ id: v, toggleHidden: true }, () => {}));
    });
    setSelectedVideos([]);
  };

  const handleCreateSeries = () => {
    setShowCreateModal(true);
  };

  const showMore = () => {
    setLimit(limit + 50);
  };

  return (
    <div id="admin-videos" className="page">
      <div className="admin-table">
        <div className="page-heading">
          <h2>Videos</h2>
        </div>
        <SeriesCreateModal
          setShowCreateModal={setShowCreateModal}
          showCreateModal={showCreateModal}
          videos={selectedVideos}
        />

        <UploadForm />

        <div className="search-form">
          <input
            placeholder="Search Videos"
            onChange={(e) => searchVideos(e.target.value)}
          />
          {/* <button type="submit"><ImSearch /></button> */}
        </div>

        {showDeleteForm && (
          <div id="delete-form">
            <p>
              Are you sure you want to delete the selected videos? They will be
              removed from all user accounts and series. This action cannot be
              undone.
            </p>

            <div className="button-container">
              <button onClick={() => handleDeleteSelection()}>
                Yes, Delete Videos
              </button>
              <button
                className="cancel"
                onClick={() => setShowDeleteForm(false)}
              >
                No, Cancel
              </button>
            </div>
          </div>
        )}
        <div className="admin-table-toggles">
          {selectedVideos.length > 0 && !showDeleteForm && (
            <div className="delete-container">
              <button onClick={() => setShowDeleteForm(true)}>
                Delete Selection
              </button>
            </div>
          )}
          {selectedVideos.length > 0 && (
            <div className="toggle-container">
              <button onClick={() => handleToggleFeatured()}>
                Toggle Featured
              </button>
              <button onClick={() => handleToggleHidden()}>
                Toggle Hidden
              </button>
              <button onClick={() => handleCreateSeries()}>
                Create Series from Selection
              </button>
            </div>
          )}
        </div>
        <ul id="admin-videos-table-rows" className="admin-table-rows">
          <li className="admin-table-row admin-table-header" key={"0"}>
            <div className="admin-table-column"></div>

            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("title");
              }}
            >
              Title{" "}
              {sortBy.key === "title" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "title" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>

            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("userName");
              }}
            >
              User{" "}
              {sortBy.key === "userName" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "userName" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>
            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("isFeatured");
              }}
            >
              Featured{" "}
              {sortBy.key === "isFeatured" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "isFeatured" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>
            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("hidden");
              }}
            >
              Hidden{" "}
              {sortBy.key === "hidden" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "hidden" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>

            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("uploaded");
              }}
            >
              Uploaded{" "}
              {sortBy.key === "uploaded" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "uploaded" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>
          </li>

          {videos &&
            videos.map((v) => {
              return (
                <li key={v._id} className="admin-table-row">
                  <div className="admin-table-column">
                    <input
                      type="checkbox"
                      value={v._id}
                      onChange={(e) => handleCheck(e)}
                    />
                  </div>
                  <div className="admin-table-column">
                    <NavLink to={`/video/${v._id}`}>{v.title}</NavLink>
                  </div>

                  <div className="admin-table-column">{v.username}</div>

                  <div className="admin-table-column featured-column">
                    {v.isFeatured ? "✅" : "-"}
                  </div>
                  <div className="admin-table-column featured-column">
                    {v.hidden ? "✅" : "-"}
                  </div>

                  <div className="admin-table-column">
                    <Moment format="MM-DD-YY">{v.createdAt}</Moment>
                  </div>
                </li>
              );
            })}
          {showMoreButton && (
            <li className="row show-more-row">
              <button onClick={() => showMore()}>Show More</button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    videos: state.video.adminVideos,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AdminVideos);
