import React from "react";


function Footer(props) {

    return (
        <div id="footer">


        </div>
    )
}


export default Footer;