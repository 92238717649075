export default function (state = { error: '', authenticated: false }, action) {
    switch (action.type) {
        case "AUTHORIZE_USER":
            return { ...state, error: '', authenticated: true, user: action.user };
        case "UNAUTHORIZE_USER":
            return { ...state, error: '', authenticated: false, user: '' };
        case "AUTH_ERROR":
            return { ...state, error: action.payload };
        case "CLEAR_AUTH_ERROR":
            return { ...state, error: '' };
        default:
            return state;
    }
}