export default function (state = { error: '', adminUsers: [], user: '' }, action) {
    switch (action.type) {

        case "UNAUTHORIZE_USER":
            return { ...state, error: '', user: '', adminUsers: [] };
        case "UPDATE_USER":
            let adminUsers = state.adminUsers;
            let updatedUserList = adminUsers.map((u) => {
                if (u._id === action.user._id) {
                    return action.user;
                } else {
                    return u;
                }
            })
            return { ...state, adminUsers: updatedUserList, user: action.user, error: '' }

        case "CREATE_USER":
            return { ...state, error: '', adminUsers: [action.user, ...state.adminUsers] }
        case "DELETE_USER":
            let updatedUsers2 = state.adminUsers.filter((s) => {
                return s._id !== action.userId;
            });
            return { ...state, error: '', adminUsers: updatedUsers2, user: null }

        case "ADMIN_GET_USERS":
            return { ...state, adminUsers: action.adminUsers, error: '' };
        case "GET_USER":
            return { ...state, user: action.user, error: '' };
        case "USER_ERROR":
            return { ...state, error: action.payload };
        case "CLEAR_USER_ERROR":
            return { ...state, error: '' };
        default:
            return state;
    }
}