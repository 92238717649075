export default function (state = { error: '', adminVideos: [], userVideos: [] }, action) {
    switch (action.type) {

        case "UNAUTHORIZE_USER":
            return { ...state, error: '', adminVideos: [], userVideos: [] };

        case "CREATE_VIDEO":


            return { ...state, adminVideos: [...action.videos, ...state.adminVideos], userVideos: [...state.userVideos, ...action.videos], video: [...action.videos], error: '' };

        case "UPDATE_VIDEO":
            let adminVideos = state.adminVideos;
            let updatedVideoList = adminVideos.map((v) => {
                if (v._id === action.video._id) {
                    return action.video;
                } else {
                    return v;
                }
            })
            return { ...state, adminVideos: updatedVideoList, video: action.video, error: '' }


        case "DELETE_VIDEO":
            let updatedVideos2 = state.adminVideos.filter((s) => {
                return s._id !== action.videoId;
            });
            return { ...state, error: '', adminVideos: updatedVideos2, video: null }
        case "ADMIN_GET_VIDEOS":
            return { ...state, adminVideos: action.adminVideos, error: '' };

        case "USER_GET_ALL_VIDEOS":
            return { ...state, userVideos: action.userVideos, error: '' };
        case "GET_VIDEO":
            return { ...state, video: action.video, error: '' };
        case "VIDEO_ERROR":
            return { ...state, error: action.payload };
        case "CLEAR_VIDEO_ERROR":
            return { ...state, error: '' };
        default:
            return state;
    }
}