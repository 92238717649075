import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactPlayer from "react-player";
import { connect, useDispatch } from "react-redux";

// Redux Actions
import { createVideo } from "../../actions/video";

function UploadForm(props) {
  const [files, setFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [showUploadButton, setShowUploadButton] = useState(true);

  let widget;

  if (window.cloudinary) {
    widget = window.cloudinary.createUploadWidget(
      {
        sources: ["local", "camera", "dropbox", "google_drive"],
        cloudName: "ballerized",
        uploadPreset: "qjp6x6o7",
        showPoweredBy: false,
        clientAllowedFormats: ["mp4", "mov"],
        text: {
          en: {
            local: {
              dd_title_single: "Drag and Drop Video Here",
              dd_title_multi: "Drag and Drop Videos Here",
            },
            queue: {
              title_uploading: "Uploading Videos",
              title: "Videos to Upload",
              title_uploading_with_counter: "Uploading {{num}} Videos",
            },
          },
        },

        styles: {
          palette: {
            window: "#141518", // black
            windowBorder: "#D5A742", // gold
            tabIcon: "#D5A742", // gold
            menuIcons: "#f5f5f5", // white
            textDark: "#141518",
            textLight: "#fff", // white
            link: "#D5A742", // button, gold
            action: "#D5A742", // done button, gold
            inactiveTabIcon: "#fff", // white
            error: "#de1a1a", // red
            inProgress: "#55FF33", // red
            complete: "#149B18", // green
            sourceBg: "#141518", // black
          },
          fonts: {
            "'Lato', sans-serif":
              "https://fonts.googleapis.com/css2?family=Lato",
          },
        },
      },
      (error, result) => {
        if (result && result.info.files) {
          setFiles(result.info.files);
        } else if (error) {
          console.log(error);
        }
      }
    );
  }

  const showWidget = () => {
    widget.open();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (formData) => {
    console.log("Here's the formData: ", formData);
    formData.deletedFiles = deletedFiles;

    let files = formData.fileMeta.map((file) => {
      return file !== null;
    });

    props.dispatch(createVideo(formData), () => {
      console.log(formData);
    });

    setFiles([]);
    setValue("fileMeta", null);
  };

  const removeFile = (index) => {
    let publicId = files[index].uploadInfo.public_id;

    setDeletedFiles([...deletedFiles, publicId]);

    setValue(`fileMeta[${index}]`, null);
    setFiles(files.filter((_, i) => i != index));
  };

  return (
    <div className="page upload-widget-container">
      <form id="upload-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="upload-items-container">
          {files &&
            files.map((f, index) => {
              setValue(`fileMeta.${index}.info`, f.uploadInfo);

              return (
                <div key={f.uploadInfo.asset_id} className="upload-item">
                  <div className="thumbnail">
                    <ReactPlayer
                      controls="true"
                      width="100%"
                      height="100%"
                      url={f.uploadInfo.secure_url}
                    />
                  </div>

                  <div className="upload-form-container">
                    {errors && (errors["title"] || errors["description"]) && (
                      <div className="input-error">
                        Title and description are required.
                      </div>
                    )}

                    <input
                      autoComplete="off"
                      name={`fileMeta.${index}.title`}
                      placeholder="Title"
                      {...register(`fileMeta.${index}.title`, {
                        required: true,
                      })}
                    />

                    <textarea
                      autoComplete="off"
                      name={`fileMeta.${index}.description`}
                      placeholder="Short Description"
                      {...register(`fileMeta.${index}.description`, {
                        required: true,
                      })}
                    />

                    <button onClick={() => removeFile(index)}>Cancel</button>
                  </div>
                </div>
              );
            })}
        </div>

        {files && files[0] && (
          <div className="button-container">
            <button type="submit">Save to Library</button>
          </div>
        )}
      </form>

      {!files[0] && (
        <div className="cloudinary-button-container">
          <button
            onClick={showWidget}
            id="upload_widget"
            className="cloudinary-button button"
          >
            Upload Videos
          </button>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(UploadForm);
