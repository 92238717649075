import React, { useEffect, useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import Title from "./components/Title/Title.js";

import ClickOutside from "../../utils/ClickOutside";

import UploadForm from "../../components/UploadForm/UploadForm";

import { connect, useDispatch } from "react-redux";

import { BsFillCaretDownFill } from "react-icons/bs";

import { userGetAllVideos } from "../../actions/video.js";

import SeriesCreateModal from "./components/SeriesCreateModal";

function Library(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (props.auth && !props.auth.user) {
      history.push("/login");
    }
  }, [props.auth]);

  const { ref, isComponentVisible, setIsComponentVisible } =
    ClickOutside(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [userVideos, setUserVideos] = useState([]);
  const [files, setFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const [sortBy, setSortBy] = useState({ key: "title", desc: false });
  const [showNoResults, setShowNoResults] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState({});
  const [selectVideosMode, setSelectVideosMode] = useState(false);

  useEffect(() => {
    if (props.auth.user) {
      dispatch(userGetAllVideos(props.auth.user._id));
    }
  }, [props.auth]);

  useEffect(() => {
    if (props.videos && props.videos.userVideos) {
      setUserVideos(props.videos.userVideos);
    }
  }, [props.videos]);

  let widget;

  if (window.cloudinary) {
    widget = window.cloudinary.createUploadWidget(
      {
        sources: ["local", "camera", "dropbox", "google_drive"],
        cloudName: "ballerized",
        uploadPreset: "qjp6x6o7",
        showPoweredBy: false,
        clientAllowedFormats: ["mp4", "mov"],
        text: {
          en: {
            local: {
              dd_title_single: "Drag and Drop Video Here",
              dd_title_multi: "Drag and Drop Videos Here",
            },
            queue: {
              title_uploading: "Uploading Videos",
              title: "Videos to Upload",
              title_uploading_with_counter: "Uploading {{num}} Videos",
            },
          },
        },

        styles: {
          palette: {
            window: "#141518", // black
            windowBorder: "#D5A742", // gold
            tabIcon: "#D5A742", // gold
            menuIcons: "#f5f5f5", // white
            textDark: "#141518",
            textLight: "#fff", // white
            link: "#D5A742", // button, gold
            action: "#D5A742", // done button, gold
            inactiveTabIcon: "#fff", // white
            error: "#de1a1a", // red
            inProgress: "#55FF33", // red
            complete: "#149B18", // green
            sourceBg: "#141518", // black
          },
          fonts: {
            "'Lato', sans-serif":
              "https://fonts.googleapis.com/css2?family=Lato",
          },
        },
      },
      (error, result) => {
        if (result && result.info.files) {
          setFiles(result.info.files);
        } else if (error) {
          console.log(error);
        }
      }
    );
  }

  const handleSort = (value) => {
    setIsComponentVisible(false);
    // If already sorting by this value, flip the order, else default to descending
    if (sortBy.key === value) {
      setSortBy({ key: value, desc: !sortBy.desc });
    } else {
      setSortBy({ key: value, desc: true });
    }

    let sortedVideos = userVideos;

    if (value === "uploaded") {
      if (sortBy.desc) {
        sortedVideos.sort((a, b) => {
          if (new Date(a[value]) < new Date(b[value])) {
            return -1;
          }

          if (new Date(a[value]) > new Date(b[value])) {
            return 1;
          }
          return 0;
        });
      } else {
        sortedVideos.sort((a, b) => {
          if (new Date(a[value]) < new Date(b[value])) {
            return 1;
          }

          if (new Date(a[value]) > new Date(b[value])) {
            return -1;
          }
          return 0;
        });
      }
    } else {
      if (sortBy.desc) {
        sortedVideos.sort((a, b) => {
          if (a[value] < b[value]) {
            return 1;
          }

          if (a[value] > b[value]) {
            return -1;
          }
          return 0;
        });
      } else {
        sortedVideos.sort((a, b) => {
          if (a[value] < b[value]) {
            return -1;
          }

          if (a[value] > b[value]) {
            return 1;
          }
          return 0;
        });
      }
    }

    setUserVideos(sortedVideos);
  };

  const handleSelectVideo = (videoId = null) => {
    console.log("clicked!", videoId);
    setSelectedVideos({
      ...selectedVideos,
      [videoId]: !selectedVideos[videoId],
    });
  };

  const showWidget = () => {
    widget.open();
  };

  const { register, handleSubmit, errors, setValue, watch } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (formData) => {
    formData.deletedFiles = deletedFiles;
    console.log(formData);

    // make sure thumbnail url transformation is set to 300 x 300
  };
  const searchVideos = (val) => {
    if (!val || val === "" || val.length < 2) {
      setSearchResults([]);
      return;
    } else {
      let results = userVideos.filter((v) => {
        if (
          v.title.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          v.description.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          v.tags
            .flatMap((r) => r.name)
            .join()
            .toLowerCase()
            .indexOf(val.toLowerCase()) > -1
        ) {
          return true;
        }
      });
      if (results.length == 0) {
        setShowNoResults(true);
      } else {
        setShowNoResults(false);
      }
      setSearchResults(results);
    }
  };

  const removeFile = (index) => {
    let publicId = files[index].uploadInfo.public_id;

    setDeletedFiles(...deletedFiles, publicId);

    setValue(`fileMeta[${index}]`, null);
    setFiles(files.filter((_, i) => i != index));

    // Delete from Cloudinary
    // cloudinary.uploader.destroy(publicId, function (error, result) { console.log(error, result) });
  };

  if (userVideos) {
    return (
      <div id="library" className="page">
        <SeriesCreateModal
          setShowCreateModal={setShowCreateModal}
          showCreateModal={showCreateModal}
          new={true}
          videos={selectedVideos ? Object.keys(selectedVideos) : []}
        />
        <div className="page-heading">
          <h2>Your Video Library</h2>
        </div>
        <div className="button-container">
          <UploadForm />
          {selectVideosMode && (
            <button
              id="create-series-button"
              className="button"
              onClick={() => setShowCreateModal(true)}
            >
              Create Series
            </button>
          )}

          <button
            className="button"
            onClick={() => {
              setSelectVideosMode(!selectVideosMode);
              setSelectedVideos({});
            }}
          >
            {selectVideosMode ? "Cancel" : "Select Videos for New Series"}
          </button>
          {/* <NavLink className="button" to="/series/create">
            Create Series
          </NavLink> */}
        </div>
        <div className="search-container">
          <div className="search-form">
            <input
              placeholder="Search Your Videos"
              onChange={(e) => searchVideos(e.target.value)}
            />
          </div>

          <div className="search-results">
            {showNoResults && (
              <div className="no-results">
                <div>No Results Found</div>
              </div>
            )}

            <div className="sort-container">
              <div className="link" onClick={() => setIsComponentVisible(true)}>
                Sort By <BsFillCaretDownFill />
              </div>

              {isComponentVisible && (
                <div ref={ref} className="menu">
                  <div
                    className="menu-item"
                    onClick={(e) => handleSort("title")}
                  >
                    Title
                  </div>
                  <div
                    className="menu-item"
                    onClick={(e) => handleSort("duration")}
                  >
                    Duration
                  </div>
                  <div
                    className="menu-item"
                    onClick={(e) => handleSort("uploaded")}
                  >
                    Date Uploaded
                  </div>
                </div>
              )}
            </div>

            {searchResults &&
              searchResults.map((r, i) => {
                const videoId = r.id ? r.id : r._id;
                return (
                  <div
                    key={r.title.replace(" ", "")}
                    className={
                      selectedVideos[videoId]
                        ? "library-item selected-video"
                        : "library-item"
                    }
                    onClick={() => {
                      if (selectVideosMode) {
                        handleSelectVideo(videoId);
                      }
                    }}
                  >
                    <div
                      className="thumbnail"
                      style={{ backgroundImage: `url(${r.thumbnailUrl})` }}
                    ></div>
                    <Title video={r} />
                    {r.tags && r.tags.length > 0 ? (
                      <div className="library-item-tags-container">
                        {r.tags.map((t) => {
                          return (
                            <div className="tag" key={t.name}>
                              {t.name}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </div>

        <div className="library-container">
          {searchResults.length === 0 &&
            userVideos.map((v, i) => {
              const videoId = v.id ? v.id : v._id;
              let hour = Math.floor(v.duration / 3600);
              v.duration %= 3600;
              let min = Math.floor(v.duration / 60);

              if (min < 10) {
                min = "0" + min;
              }
              let sec = Math.ceil(v.duration % 60);

              if (sec < 10) {
                sec = "0" + sec;
              }

              return (
                <div
                  className={
                    selectedVideos[videoId]
                      ? "library-item selected-video"
                      : "library-item"
                  }
                  onClick={() => {
                    if (selectVideosMode) {
                      handleSelectVideo(videoId);
                    }
                  }}
                >
                  <div
                    className="thumbnail"
                    style={{ backgroundImage: `url(${v.thumbnailUrl})` }}
                  >
                    {hour ? `${hour}:` : ""}
                    {min}:{sec}
                  </div>
                  <Title video={v} />
                  {v.tags && v.tags.length > 0 ? (
                    <div className="library-item-tags-container">
                      {v.tags.map((t) => {
                        return (
                          <div className="tag" key={t.name}>
                            {t.name}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    videos: state.video,
  };
};

export default connect(mapStateToProps)(Library);
