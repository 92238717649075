import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import Logo from "../../images/ballerized-full-logo.png";

function Information(props) {
  const [showLogo, setShowLogo] = useState(false);

  var standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  useEffect(() => {
    // iPhone
    if (ios) {
      if (!standalone && safari) {
        if (!props.auth.authenticated) {
          setShowLogo(true);
        }
      } else if (!standalone && !safari) {
        // iOS webview
        return;
      }
      // Android
    } else {
      if (userAgent.includes("wv")) {
        // Android webview
        return;
      } else {
        // Chrome

        if (!props.auth.authenticated) {
          setShowLogo(true);
        }
      }
    }
  }, [props.auth]);

  return (
    <div className="information">
      {setShowLogo && (
        <NavLink to="/login">
          <div className="logo-container">
            <img alt="Ballerized Logo" src={Logo} />
          </div>
        </NavLink>
      )}
      {props.type === "privacy" && <PrivacyPolicy />}
      {props.type === "terms" && <TermsOfUse />}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(Information);
