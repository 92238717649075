import React, { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import { GoogleLogin } from "react-google-login";

import jwt_decode from "jwt-decode";

import Logo from "../../images/ballerized-full-logo.png";

import config from "../../config/keys";

// Redux Actions
import { login, clearAuthError, unauthorizeUser } from "../../actions/auth";

const clientId = config.googleClientId;

function Login(props) {
  const success = (response) => {
    console.log("Successful Google Response: ", response.profileObj);
    // setValue("email", response.profileObj.email);
    // setValue("googleId", response.profileObj.googleId);
    let formData = {
      email: response.profileObj.email,
      googleId: response.profileObj.googleId,
    };
    dispatch(
      login(formData, () => {
        history.push("/library");

        // const token = localStorage.getItem("token");
        // if (token) {
        //   let decodedToken = jwt_decode(token);

        // }
      })
    );
  };

  const error = (response) => {
    console.error(response);
  };

  const loading = () => {
    console.log("loading");
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    if (props.auth && props.auth.authenticated) {
      if (props.auth.error) {
        dispatch(unauthorizeUser());
      }
      console.log("Auth stuff: ", props.auth);
      if (props.auth.user && props.auth.user.admin) {
        history.push("/admin/videos");
      } else {
        history.push("/library");
      }
    }
  }, [props.auth]);

  const clearError = () => {
    if (props.auth.error) {
      dispatch(clearAuthError());
    }
  };

  // Dispatch action
  const onSubmit = (formData) => {
    console.log("on submit");
    dispatch(
      login(formData, () => {
        history.push("/library");
      })
    );
  };

  return (
    <div id="page-login" className="auth">
      <div className="auth-form-container">
        <div className="auth-logo-container">
          <img className="auth-logo" src={Logo} />
        </div>

        <div className="auth-form">
          <h3>Log in to your account.</h3>

          {props.auth && props.auth.error && !props.auth.error.message && (
            <p className="form-error">{props.auth.error}</p>
          )}

          <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-input-container">
                <label htmlFor="email">Email or Username</label>
                <input
                  id="email"
                  name="email"
                  {...register("email", { required: true })}
                  onChange={(e) => clearError(e.target.value)}
                />
                {errors && errors["email"] && (
                  <div className="input-error">
                    Email or username is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-input-container">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  {...register("password", { required: true })}
                  onChange={(e) => clearError(e.target.value)}
                />
                {errors && errors["password"] && (
                  <div className="input-error">Password is required.</div>
                )}
              </div>
            </div>

            <div className="button-container">
              <button type="submit">Log In</button>

              <GoogleLogin
                clientId={clientId}
                onSuccess={success}
                onFailure={error}
                onRequest={loading}
                offline={false}
                isSignedIn={false}
                approvalPrompt="force"
                responseType="id_token"
                theme="dark"
                // disabled
                prompt="consent"
                // className='button'
              />
            </div>

            <div className="login-form-links">
              <Link to="/forgot-password" onClick={clearError}>
                Forgot your password?
              </Link>
              {/* <Link to="/signup" onClick={clearError}>Don't have an account? Sign up.</Link> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Login);
