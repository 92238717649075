import React from "react";
import { connect, useDispatch } from 'react-redux';
import { deleteSeries } from "../../../actions/series";


function DeleteForm(props) {
    const dispatch = useDispatch();

    const handleDeleteSeries = () => {

        props.hideForm();
        dispatch(deleteSeries(props.series.series._id,
            () => {
                props.redirect();
            }));
    }

    return (
        <div id="delete-form">

            <p>Are you sure you want to delete this series? Your videos will remain in your library.</p>

            {props.series && props.series.error && !props.series.error.message && (
                <div className="edit-form-field">
                    <p className="form-error-inline">{props.series.error}</p>
                </div>
            )}

            <div className="button-container">
                <button onClick={() => handleDeleteSeries()}>Yes, Delete Series</button>
                <button className="cancel" onClick={() => props.hideForm()}>No, Cancel</button>
            </div>



        </div>


    );


}

const mapStateToProps = (state) => {
    return {
        series: state.series
    }
}

export default connect(mapStateToProps)(DeleteForm);