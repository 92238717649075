import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';

// Redux Actions 
import { clearAuthError, checkResetToken, resetPassword } from '../../actions/auth';

import Logo from "../../images/ballerized-full-logo.png";


function ResetPassword(props) {

    const dispatch = useDispatch();
    let { token } = useParams();

    const [showForm, setShowForm] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const { register, handleSubmit } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        if (token) {
            dispatch(checkResetToken(token, () => {
                dispatch(clearAuthError());
                setShowForm(true)
            }));
        }
    }, [token, dispatch]);

    const onSubmit = (formData) => {


        dispatch(resetPassword({ password: formData.password, token }, () => {
            dispatch(clearAuthError());
            setShowConfirmation(true);
            setShowForm(false);
        }));
    }

    return (
        <div id="page-login" className="auth">

            <div className="auth-form-container">

                <div className="auth-logo-container"><img alt="Logo" className="auth-logo" src={Logo} /></div>

                {
                    showConfirmation &&
                    <div><p>Your password has been reset.</p><p><NavLink to="/login">Please log in with your new password here.</NavLink></p></div>
                }

                {showForm && !showConfirmation &&

                    <div className="auth-form">
                        <form id="login-form" onSubmit={handleSubmit(onSubmit)}>

                            <h3>Create a new password.</h3>

                            {props.auth && props.auth.error && !props.auth.error.message && (
                                <p className="form-error">{props.auth.error}</p>
                            )}

                            <div className="form-row">
                                <div className="form-input-container">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" name="password" {...register('password')} />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-input-container">
                                    <label htmlFor="password-confirm">Confirm Password</label>
                                    <input type="password" name="password-confirm" {...register('password-confirm')} />
                                </div>
                            </div>

                            <div className="button-container">
                                <button type="submit">Change Password</button>
                            </div>

                        </form>

                    </div>
                }

            </div>


        </div>
    );

}


const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(ResetPassword);