import React, { createRef } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Components
import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar.js";
import AdminSidebar from "../components/AdminSidebar/AdminSidebar.js";
import Login from "../views/Login/Login";
import ForgotPassword from "../views/ForgotPassword/ForgotPassword";
import ResetPassword from "../views/ResetPassword/ResetPassword";
import ConfirmEmail from "../views/ConfirmEmail/ConfirmEmail";
import Library from "../views/Library/Library";
import Series from "../views/Series/Series";
import Video from "../views/Video/Video";
import User from "../views/User/User";
import Account from "../views/Account/Account";
import AdminVideos from "../views/AdminVideos/AdminVideos";
import AdminUsers from "../views/AdminUsers/AdminUsers";
import AdminSeries from "../views/AdminSeries/AdminSeries";
import AdminTags from "../views/AdminTags/AdminTags";
import Information from "../views/Information/Information";

// Global Styles
import "../styles/global.scss";

function AppRouter(props) {
  const appRef = createRef();

  return (
    <Router>
      <div className="app" ref={appRef}>
        {props.auth && props.auth.user && props.auth.user.admin ? (
          <AdminSidebar />
        ) : props.auth.user ? (
          <Sidebar />
        ) : (
          <div></div>
        )}
        <div
          className={
            props.auth && props.auth.user
              ? "view-container"
              : "view-container no-user"
          }
        >
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/reset-password/:token">
              <ResetPassword />
            </Route>
            <Route path="/confirm-email/:token">
              <ConfirmEmail />
            </Route>
            <Route path="/account">
              <Account />
            </Route>
            <Route path="/library">
              <Library />
            </Route>
            <Route path="/series/create">
              <Series existingSeries={false} />
            </Route>
            <Route path="/series/:seriesId">
              <Series existingSeries={true} />
            </Route>
            <Route path="/video/:videoId">
              <Video />
            </Route>
            <Route path="/user/:userId">
              <User />
            </Route>
            <Route path="/admin/videos">
              <AdminVideos />
            </Route>
            <Route path="/admin/series">
              <AdminSeries />
            </Route>
            <Route path="/admin/tags">
              <AdminTags />
            </Route>
            <Route path="/admin/users">
              <AdminUsers />
            </Route>
            <Route path="/privacy">
              <Information type="privacy" />
            </Route>
            <Route path="/terms">
              <Information type="terms" />
            </Route>
            <Route path="/">
              <Library />
            </Route>
          </Switch>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AppRouter);
