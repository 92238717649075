import React from "react";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";

import { createUser } from "../../../actions/user";

function NewUserForm(props) {
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  // Dispatch action
  const onSubmit = (data) => {
    data.admin = true;

    dispatch(
      createUser(data, () => {
        console.log(data);
        props.hideForm();
      })
    );
  };

  return (
    <form id="edit-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="edit-form-field">
        <label htmlFor="username">Username</label>
        <input autoComplete="off" name={"username"} {...register("username")} />
      </div>

      <div className="edit-form-field">
        <label htmlFor="username">Username</label>
        <input autoComplete="off" name={"username"} {...register("username")} />
      </div>

      <div className="edit-form-field">
        <label htmlFor="email">Email</label>
        <input
          autoComplete="off"
          name={"email"}
          {...register("email", { required: true })}
        />
      </div>

      {errors && errors["email"] && <p>Email address is required.</p>}

      <div className="edit-form-field">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          name="password"
          {...register("password", { required: true })}
        />
        {errors && errors["password"] && (
          <div className="input-error">Password is required.</div>
        )}
      </div>

      <div className="edit-form-field">
        <label htmlFor="bio">Bio</label>
        <textarea
          autoComplete="off"
          defaultValue={props.user.bio}
          name={"bio"}
          {...register("bio")}
        />
      </div>

      {props.user && props.user.error && !props.user.error.message && (
        <div className="edit-form-field">
          <p className="form-error-inline">{props.user.error}</p>
        </div>
      )}

      <div className="button-container">
        <button type="submit">Create Admin</button>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(NewUserForm);
