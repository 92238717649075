import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import ReactPlayer from "react-player";
import EditForm from "./components/EditForm";
import DeleteForm from "./components/DeleteForm";
import Moment from "react-moment";
import "moment-timezone";

import { connect } from "react-redux";

// Redux Actions
import { getUser } from "../../actions/user";

function User(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (props.auth && !props.auth.user) {
      history.push("/login");
    }
  }, [props.auth, history]);

  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [user, setUser] = useState();

  const { userId } = useParams();

  useEffect(() => {
    dispatch(getUser(userId));
  }, [userId, dispatch]);

  useEffect(() => {
    if (props.user) {
      setUser(props.user);
    }
  }, [props.user]);

  if (user) {
    return (
      <div id="user" className="page">
        <div className="user-title">
          <h2>{user.username}</h2>
          <div
            className="avatar"
            style={{ backgroundImage: `url(${user.avatar})` }}
          ></div>
        </div>

        <div className="action-button-container">
          {!showEditForm && !showDeleteForm && (
            <button className="edit" onClick={() => setShowEditForm(true)}>
              Edit Details
            </button>
          )}

          {!showDeleteForm && !showEditForm && (
            <button className="delete" onClick={() => setShowDeleteForm(true)}>
              Delete Account
            </button>
          )}
        </div>

        {showEditForm && !showDeleteForm && (
          <EditForm user={user} hideForm={() => setShowEditForm(false)} />
        )}

        {showDeleteForm && !showEditForm && (
          <DeleteForm
            user={user}
            userId={user._id}
            hideForm={() => setShowDeleteForm(false)}
          />
        )}

        {!showEditForm && (
          <div className="user-details">
            <div className="user-details-item">
              <div className="name">Name</div>
              <div className="details">{user.username}</div>
            </div>

            <div className="user-details-item">
              <div className="name">Email</div>
              <div className="details">{user.email}</div>
            </div>
            <div className="user-details-item">
              <div className="name">Joined</div>
              <div className="details">
                <Moment format="MM-DD-YY">{new Date(user.createdAt)}</Moment>
              </div>
            </div>

            <div className="user-details-item">
              <div className="name">Bio</div>
              <div className="details">{user.bio}</div>
            </div>

            {user.instructor && (
              <div className="user-details-item">
                <div className="name">Role</div>
                <div className="details">Instructor</div>
              </div>
            )}

            {user.athlete && (
              <div className="user-details-item">
                <div className="name">{!user.instructor && "Role"}</div>
                <div className="details">Athlete</div>
              </div>
            )}

            {props.auth.user.admin && user.admin && (
              <div className="user-details-item">
                <div className="name">
                  {!user.instructor && !user.athlete && "Role"}
                </div>
                <div className="details">Admin</div>
              </div>
            )}
          </div>
        )}

        <div className="user-preview">
          <ReactPlayer
            controls="true"
            width="100%"
            height="100%"
            url={user.url}
          />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(User);
