import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";

import { FaSortUp } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";

import Moment from "react-moment";
import "moment-timezone";

import { connect } from "react-redux";

import SeriesForm from "../Series/components/SeriesForm";

// Redux Actions
import {
  adminGetSeries,
  deleteSeries,
  updateSeries,
  createSeries,
} from "../../actions/series";

function AdminSeries(props) {
  const history = useHistory();

  useEffect(() => {
    if (
      !props.auth.authenticated ||
      (props.auth.user && !props.auth.user.admin)
    ) {
      history.push("/login");
    }
  }, [props.auth]);

  const [series, setSeries] = useState([]);
  const [sortBy, setSortBy] = useState({ key: "createdAt", desc: true });
  const [limit, setLimit] = useState(100);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);

  useEffect(() => {
    props.dispatch(adminGetSeries(sortBy));
  }, [sortBy]);

  useEffect(() => {
    if (props.series && props.series.length > limit) {
      setSeries(props.series.slice(0, limit));
      setShowMoreButton(true);
    } else {
      setSeries(props.series);
    }
  }, [props.series, limit]);
  console.log("Props: ", props);

  const handleCheck = (e) => {
    if (e.target.checked) {
      setSelectedSeries([e.target.value, ...selectedSeries]);
    } else {
      let removedSeries = selectedSeries.filter((v) => {
        return v != e.target.value;
      });
      setSelectedSeries(removedSeries);
    }
  };

  // Up arrow means in ascending order
  // Down arrow means in descending order

  const handleSort = (column) => {
    // If already sorting by this column, flip the order, else default to descending
    if (sortBy.key === column) {
      setSortBy({ key: column, desc: !sortBy.desc });
    } else {
      setSortBy({ key: column, desc: true });
    }
  };

  // const handleFeatured = () => {
  //   selectedSeries.forEach((id) => {
  //     props.dispatch(
  //       updateSeries({ seriesId: id, toggleFeatured: true }, () => {
  //         console.log("");
  //       })
  //     );
  //   });

  //   setSelectedSeries([]);
  // };

  const handlePremium = () => {
    selectedSeries.forEach((id) => {
      props.dispatch(
        updateSeries({ seriesId: id, togglePremium: true }, () => {
          console.log("");
        })
      );
    });

    setSelectedSeries([]);
  };

  const handleHidden = () => {
    selectedSeries.forEach((id) => {
      props.dispatch(
        updateSeries({ seriesId: id, toggleHidden: true }, () => {
          console.log("");
        })
      );
    });

    setSelectedSeries([]);
  };

  // Handling front end for now, if too many series, move search to API
  const searchSeries = (val) => {
    console.log("search props.series: ", props.series);
    if (!val || val === "" || val.length < 1) {
      return;
    } else {
      let results = props.series.filter((s) => {
        if (
          s.title.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          s.description.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
          (s.username &&
            s.username.toLowerCase().indexOf(val.toLowerCase()) > -1)
        ) {
          return true;
        }
      });
      setSeries(results);
    }
  };

  const handleDeleteSelection = () => {
    selectedSeries.forEach((s) => {
      props.dispatch(
        deleteSeries(s, () => {
          props.dispatch(adminGetSeries(sortBy));
        })
      );
    });

    setShowDeleteForm(false);
    setSelectedSeries([]);
  };

  const showMore = () => {
    setLimit(limit + 50);
  };

  return (
    <div id="admin-series" className="page">
      <div className="admin-table">
        <div className="page-heading">
          <h2>Series</h2>
        </div>

        {!showCreateForm && (
          <div className="action-button-container">
            <button onClick={() => setShowCreateForm(true)}>
              <AiOutlinePlus /> New Series
            </button>
            {/* <button onClick={() => history.push({
              pathname:"/admin/videos",
              
            })}>
              <AiOutlinePlus /> New Series
            </button> */}
          </div>
        )}

        {showCreateForm && (
          <SeriesForm
            // className="modal-content"
            // videos={[]}
            series={series}
            new={true}
            hideForm={() => setShowCreateForm(false)}
          />
        )}

        <div className="search-form">
          <input
            placeholder="Search Series"
            onChange={(e) => searchSeries(e.target.value)}
          />
        </div>

        {showDeleteForm && (
          <div id="delete-form">
            <p>
              Are you sure you want to delete the selected series? This will NOT
              delete individual videos, which will remain in users' libraries.
              This action cannot be undone.
            </p>

            <div className="button-container">
              <button onClick={() => handleDeleteSelection()}>
                Yes, Delete Series
              </button>
              <button
                className="cancel"
                onClick={() => setShowDeleteForm(false)}
              >
                No, Cancel
              </button>
            </div>
          </div>
        )}

        <div className="delete-container">
          {selectedSeries.length > 0 && !showDeleteForm && (
            <button onClick={() => setShowDeleteForm(true)}>
              Delete Selection
            </button>
          )}

          {selectedSeries.length > 0 && (
            <button className="premium" onClick={handlePremium}>
              Toggle Premium
            </button>
          )}
          {selectedSeries.length > 0 && (
            <button className="premium" onClick={handleHidden}>
              Toggle Hidden
            </button>
          )}
        </div>

        <ul id="admin-series-table-rows" className="admin-table-rows">
          <li className="admin-table-row admin-table-header">
            <div className="admin-table-column"></div>

            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("title");
              }}
            >
              Title{" "}
              {sortBy.key === "title" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "title" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>

            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("isPremium");
              }}
            >
              Premium{" "}
              {sortBy.key === "isPremium" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "isPremium" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>
            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("hidden");
              }}
            >
              Hidden{" "}
              {sortBy.key === "hidden" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "hidden" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>
            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("cost");
              }}
            >
              Cost{" "}
              {sortBy.key === "cost" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "cost" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>
            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("username");
              }}
            >
              User{" "}
              {sortBy.key === "username" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "username" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>

            <div
              className="admin-table-column"
              onClick={() => {
                handleSort("createdAt");
              }}
            >
              Created{" "}
              {sortBy.key === "createdAt" && !sortBy.desc ? (
                <FaSortUp />
              ) : sortBy.key === "createdAt" ? (
                <FaSortDown />
              ) : (
                ""
              )}
            </div>
          </li>

          {series &&
            series.map((s) => {
              return (
                <li className="admin-table-row" key={s._id}>
                  <div className="admin-table-column">
                    <input
                      type="checkbox"
                      checked={selectedSeries.includes(s._id) ? true : false}
                      value={s._id}
                      onChange={(e) => handleCheck(e)}
                    />
                  </div>

                  <div className="admin-table-column">
                    <NavLink to={`/series/${s._id}`}>{s.title}</NavLink>
                  </div>

                  <div className="admin-table-column">
                    {s.isPremium ? <FaCheck /> : ""}
                  </div>
                  <div className="admin-table-column">
                    {s.hidden ? <FaCheck /> : ""}
                  </div>
                  <div className="admin-table-column">
                    {s.cost ? `$${s.cost}` : ""}
                  </div>
                  <div className="admin-table-column">{s.username}</div>

                  <div className="admin-table-column">
                    <Moment format="MM-DD-YY">{s.createdAt}</Moment>
                  </div>
                </li>
              );
            })}
          {showMoreButton && (
            <li className="row show-more-row">
              <button onClick={() => showMore()}>Show More</button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    series: state.series.adminSeries,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AdminSeries);
