import axios from 'axios';
import config from '../config/keys';

const ROOT = config.rootUrl;

// TO DO: Update all endpoints

export function deleteUser(id, callback) {
    return function (dispatch) {

        axios.delete(`${ROOT}/user/${id}`,
            {
                headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
            })
            .then(response => {
                if (response.data.error) {
                    dispatch(userError(response.data.error));
                } else {
                    dispatch(clearUserError());
                    dispatch({ type: "DELETE_USER", userId: response.data.userId });
                    callback();
                }
            })
            .catch((error) => {
                dispatch(userError("There was an error deleting this user."));
            });
    }
}

export function createUser(data, callback) {
    return function (dispatch) {

        axios.post(`${ROOT}/user/new`, data,
            {
                headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
            })
            .then(response => {
                if (response.data.error) {
                    dispatch(userError(response.data.error));
                } else {
                    dispatch(clearUserError());
                    dispatch({ type: "CREATE_USER", user: response.data.user });

                    if (callback) {
                        callback();
                    }

                }
            })
            .catch((error) => {
                dispatch(userError("There was an error creating this user."));
            });
    }
}


export function adminGetUsers(sortBy) {

    let direction = sortBy && sortBy.desc === true ? -1 : 1;
    let key = sortBy && sortBy.key ? sortBy.key : null;

    return function (dispatch) {
        axios.get(`${ROOT}/admin/users?sortby=${key}&sortorder=${direction}`, {
            headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
        })
            .then(response => {
                if (response.data.error) {
                    dispatch(userError(response.data.error));
                } else {
                    dispatch(clearUserError());
                    dispatch({ type: "ADMIN_GET_USERS", adminUsers: response.data.users });
                }
            })
            .catch((error) => {
                dispatch(userError("There was an error fetching users."));
            });
    }
}


export function adminSearchUsers(sortBy, searchText) {
    let direction = sortBy && sortBy.desc === true ? -1 : 1;
    let key = sortBy && sortBy.key ? sortBy.key : null;

    if (searchText) {
        return function (dispatch) {
            axios.get(`${ROOT}/users/search?sortby=${key}&sortorder=${direction}&searchtext=${searchText}`, {
                headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
            })
                .then(response => {
                    if (response.data.error) {
                        dispatch(userError(response.data.error));
                    } else {
                        dispatch(clearUserError());
                        dispatch({ type: "ADMIN_GET_USERS", adminUsers: response.data.users });
                    }
                })
                .catch((error) => {
                    dispatch(userError("There was an error fetching users."));
                });
        }
    }
}


export function getUser(userId, callback) {

    return function (dispatch) {
        axios.get(`${ROOT}/user/${userId}`, {
            headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
        })
            .then(response => {
                if (response.data.error) {
                    dispatch(userError(response.data.error));
                } else {
                    dispatch(clearUserError());
                    dispatch({ type: "GET_USER", user: response.data.user });

                    if (callback) {
                        callback();
                    }

                }
            })
            .catch((error) => {
                dispatch(userError("There was an error loading this user."));
            });
    }
}


export function updateUser(formData, callback) {

    return function (dispatch) {
        axios.put(`${ROOT}/user`, formData, {
            headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
        })
            .then(response => {
                if (response.data.error) {
                    dispatch(userError(response.data.error));
                } else {
                    dispatch({ type: "UPDATE_USER", user: response.data.user });
                    dispatch(clearUserError());
                    if (callback) {
                        callback();
                    }

                }
            })
            .catch((error) => {
                dispatch(userError("There was an error loading this user."));
            });
    }
}

export function userError(error) {
    return {
        type: "USER_ERROR",
        payload: error
    };
}

export function clearUserError() {
    return {
        type: "CLEAR_USER_ERROR"
    };
}
