import axios from 'axios';
import config from '../config/keys';

const ROOT = config.rootUrl;

export function updateWorkoutVideo(data, callback) {
    return function (dispatch) {

        axios.put(`${ROOT}/workout_video`, data,
            {
                headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
            })
            .then(response => {


                if (response.data.error) {
                    dispatch(workoutVideoError(response.data.error));
                } else {
                    dispatch(clearWorkoutVideoError());
                    dispatch({ type: "UPDATE_WORKOUT_VIDEO", workoutVideo: response.data.workoutVideo });

                    if (callback) {
                        callback();
                    }

                }
            })
            .catch((error) => {
                dispatch(workoutVideoError("There was an error updating this video."));
            });
    }
}



export function getWorkoutVideo({ videoId, seriesId }) {
    return function (dispatch) {
        axios.get(`${ROOT}/workout_video/${videoId}/series/${seriesId}`, {
            headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
        })
            .then(response => {
                if (response.data.error) {
                    dispatch(workoutVideoError(response.data.error));
                } else {
                    dispatch(clearWorkoutVideoError());
                    dispatch({ type: "GET_WORKOUT_VIDEO", video: response.data.video });
                }
            })
            .catch((error) => {
                dispatch(workoutVideoError("There was an error loading this video."));
            });
    }
}


export function deleteWorkoutVideo({ videoId, seriesId }, callback) {
    return function (dispatch) {
        axios.delete(`${ROOT}/workout_video/${videoId}/series/${seriesId}`, {
            headers: { 'Authorization': 'JWT ' + localStorage.getItem('token') }
        })
            .then(response => {
                if (response.data.error) {
                    dispatch(workoutVideoError(response.data.error));
                } else {
                    dispatch(clearWorkoutVideoError());
                    dispatch({ type: "UPDATE_SERIES", series: response.data.series });

                    if (callback) {
                        callback();
                    }
                }
            })
            .catch((error) => {
                dispatch(workoutVideoError("There was an error loading this video."));
            });
    }
}

export function workoutVideoError(error) {
    return {
        type: "WORKOUT_VIDEO_ERROR",
        payload: error
    };
}

export function clearWorkoutVideoError() {
    return {
        type: "CLEAR_WORKOUT_VIDEO_ERROR"
    };
}
