import React from "react";
import { connect, useDispatch } from 'react-redux';
import { deleteVideo } from "../../../actions/video.js";


function DeleteForm(props) {

    const dispatch = useDispatch();

    const handleDeleteVideo = () => {
        props.hideForm();
        dispatch(deleteVideo(props.videoId,
            () => {
                props.redirect();
            }));
    }

    return (
        <div id="delete-form">

            <p>Are you sure you want to delete this video and all of its information? This action cannot be undone.</p>

            {props.video && props.video.error && !props.video.error.message && (
                <p className="form-error">{props.video.error}</p>
            )}

            <div className="button-container">
                <button onClick={() => handleDeleteVideo()}>Yes, Delete Video</button>
                <button className="cancel" onClick={() => props.hideForm()}>No, Cancel</button>
            </div>



        </div>


    );


}

const mapStateToProps = (state) => {
    return {
        video: state.video
    }
}

export default connect(mapStateToProps)(DeleteForm);