import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";

import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import Logo from "../../images/ballerized-full-logo.png";

import { unauthorizeUser } from "../../actions/auth";
import { userGetAllSeries } from "../../actions/series";

function Sidebar(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showMenu, setShowMenu] = useState(true);

  const handleLogOut = () => {
    dispatch(unauthorizeUser(() => history.push("/login")));
  };

  useEffect(() => {
    dispatch(userGetAllSeries(props.user._id));
  }, [dispatch, props.user]);

  return (
    <div id="sidebar" className={`${showMenu ? "sidebar active" : "sidebar"}`}>
      <div onClick={() => setShowMenu(!showMenu)} className="menu-icon">
        {showMenu && <MdClose />}

        {!showMenu && <GiHamburgerMenu />}
      </div>

      <div className="logo-container">
        <img alt="Ballerized Logo" src={Logo} />
      </div>

      <div className="sidebar-menu">
        <NavLink to="/account" onClick={() => setShowMenu(false)}>
          Your Account
        </NavLink>

        <NavLink to="/library" onClick={() => setShowMenu(false)}>
          Your Library
        </NavLink>
        {props.series.userSeries.length > 0 ? (
          <h4>Your Series</h4>
        ) : null
        // <NavLink to="/series/create" onClick={() => setShowMenu(false)}>
        //   Create a Series
        // </NavLink>
        }

        {props.series.userSeries.length > 0 && (
          <div className="series-list-container">
            {props.series.userSeries.map((s) => {
              return (
                <NavLink
                  key={s._id}
                  to={`/series/${s._id}`}
                  onClick={() => setShowMenu(false)}
                >
                  {s.title}
                </NavLink>
              );
            })}
          </div>
        )}

        <a href="/login" onClick={handleLogOut}>
          Log Out
        </a>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    series: state.series,
  };
};

export default connect(mapStateToProps)(Sidebar);
