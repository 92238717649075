import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { connect, useDispatch } from "react-redux";
import Tags from "../../../components/Tags/Tags";

// Redux Actions
import { createSeries, updateSeries } from "../../../actions/series";
import SeriesForm from "../../Series/components/SeriesForm";

function SeriesCreateModal(props) {
  if (!props.showCreateModal) {
    return null;
  }
  const handleHide = () => {
    props.setShowCreateModal(false);
  };
  return (
    <div className="modal">
      <div className="modal-header">
        <h2>Create a New Series</h2>
      </div>
      <SeriesForm
        className="modal-content"
        videos={props.videos}
        new={props.new}
        series={props.series}
        hideForm={() => props.setShowCreateModal(false)}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    series: state.series,
  };
};

export default connect(mapStateToProps)(SeriesCreateModal);
